























import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { makeLocaleSelectorOptions } from 'library/services/i18n/locales'

export default

	data: ->
		locales: makeLocaleSelectorOptions()
		open: false

	computed:

		# Determine the active country, like ignoring language
		activeCountryCode: -> @$i18n.defaultLocale.split('_')[1].toLowerCase()

		# Return the active locale entry
		activeLocale: -> @locales.find ({ countryCode }) =>
			countryCode == @activeCountryCode

		localeAriaLabel: ->
			if @open then "Locale selector open"
			else "Locale selector closed"

	methods:

		reveal: ->
			@open = !@open
			if @open then @$defer => disableBodyScroll @$refs.localeScroller
			else enableBodyScroll @$refs.localeScroller

		getLocaleFlag: ({ countryCode }) -> "#{process.env.NUXT_APP_URL}/imgs/flags/#{countryCode}.svg"

