























import HeightTween from 'vue-height-tween-transition'
import SubscriptionCheckbox from './subscription-checkbox'

export default
	name: "SubscriptionOptIn"

	components: { HeightTween, SubscriptionCheckbox }

	props:
		label: String
		sellingPlanAllocations: Array
		# The current selling plan allocation
		value: Object
		loading: Boolean
		disabled: Boolean

	data: ->
		state: @findAllocation()

	# Fetch customer data to see if subscriptions exist
	created: ->
		@$store.dispatch 'customer/navigation/fetchUnlessHydrating'

	watch:
		value: -> @state = @findAllocation()
		state: -> @$emit 'input', @state

	computed:

		# Render the component if customer does not have active subscriptions
		# and this product is subscribeable
		shouldRender: ->
			@customerHydrated &&
			@customerHasNoSubscriptions &&
			@sellingPlanAllocations?.length &&
			@$store.getters['customer/canPurchaseSubscriptions']

		customerHydrated: -> @$store.state.customer.hydrated

		customerUnsentSubscriptions: ->
			@$store.state.customer.navigation.unsentSubscriptions

		customerHasNoSubscriptions: ->
			!@customerUnsentSubscriptions || !@customerUnsentSubscriptions?.length

		# Internal subscribing state; not OrderGroove based data
		isSubscribing: -> !!@state

	methods:
		findAllocation: () ->
			@sellingPlanAllocations.find (allocation) =>
				@value?.sellingPlan?.id == allocation?.sellingPlan?.id

		updateEnrollment: (checked) ->
			if checked
				@state = @sellingPlanAllocations[0]
			else
				@state = undefined

