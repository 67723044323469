export const CloakFormCheckbox = () => import('../../../node_modules/@cloak-app/form/components/checkbox.vue' /* webpackChunkName: "components/cloak-form-checkbox" */).then(c => wrapFunctional(c.default || c))
export const CloakFormField = () => import('../../../node_modules/@cloak-app/form/components/field.vue' /* webpackChunkName: "components/cloak-form-field" */).then(c => wrapFunctional(c.default || c))
export const CloakFormPassword = () => import('../../../node_modules/@cloak-app/form/components/password.coffee' /* webpackChunkName: "components/cloak-form-password" */).then(c => wrapFunctional(c.default || c))
export const CloakFormSelect = () => import('../../../node_modules/@cloak-app/form/components/select.vue' /* webpackChunkName: "components/cloak-form-select" */).then(c => wrapFunctional(c.default || c))
export const CloakFormTextfield = () => import('../../../node_modules/@cloak-app/form/components/textfield.vue' /* webpackChunkName: "components/cloak-form-textfield" */).then(c => wrapFunctional(c.default || c))
export const BlocksActivityListing = () => import('../../components/blocks/activity-listing.vue' /* webpackChunkName: "components/blocks-activity-listing" */).then(c => wrapFunctional(c.default || c))
export const BlocksActivitySegment = () => import('../../components/blocks/activity-segment.vue' /* webpackChunkName: "components/blocks-activity-segment" */).then(c => wrapFunctional(c.default || c))
export const BlocksAthletesListing = () => import('../../components/blocks/athletes-listing.vue' /* webpackChunkName: "components/blocks-athletes-listing" */).then(c => wrapFunctional(c.default || c))
export const BlocksBlockquote = () => import('../../components/blocks/blockquote.vue' /* webpackChunkName: "components/blocks-blockquote" */).then(c => wrapFunctional(c.default || c))
export const BlocksByTheNumbers = () => import('../../components/blocks/by-the-numbers.vue' /* webpackChunkName: "components/blocks-by-the-numbers" */).then(c => wrapFunctional(c.default || c))
export const BlocksCardCarousel = () => import('../../components/blocks/card-carousel.vue' /* webpackChunkName: "components/blocks-card-carousel" */).then(c => wrapFunctional(c.default || c))
export const BlocksCardGrid = () => import('../../components/blocks/card-grid.vue' /* webpackChunkName: "components/blocks-card-grid" */).then(c => wrapFunctional(c.default || c))
export const BlocksCarouselSlide = () => import('../../components/blocks/carousel-slide.vue' /* webpackChunkName: "components/blocks-carousel-slide" */).then(c => wrapFunctional(c.default || c))
export const BlocksCollectionTout = () => import('../../components/blocks/collection-tout.vue' /* webpackChunkName: "components/blocks-collection-tout" */).then(c => wrapFunctional(c.default || c))
export const BlocksColorTileCarousel = () => import('../../components/blocks/color-tile-carousel.vue' /* webpackChunkName: "components/blocks-color-tile-carousel" */).then(c => wrapFunctional(c.default || c))
export const BlocksEmbed = () => import('../../components/blocks/embed.vue' /* webpackChunkName: "components/blocks-embed" */).then(c => wrapFunctional(c.default || c))
export const BlocksFamilyOfBrands = () => import('../../components/blocks/family-of-brands.vue' /* webpackChunkName: "components/blocks-family-of-brands" */).then(c => wrapFunctional(c.default || c))
export const BlocksFaq = () => import('../../components/blocks/faq.vue' /* webpackChunkName: "components/blocks-faq" */).then(c => wrapFunctional(c.default || c))
export const BlocksFeaturedIconCards = () => import('../../components/blocks/featured-icon-cards.vue' /* webpackChunkName: "components/blocks-featured-icon-cards" */).then(c => wrapFunctional(c.default || c))
export const BlocksImageAndText = () => import('../../components/blocks/image-and-text.vue' /* webpackChunkName: "components/blocks-image-and-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksInlineTouts = () => import('../../components/blocks/inline-touts.vue' /* webpackChunkName: "components/blocks-inline-touts" */).then(c => wrapFunctional(c.default || c))
export const BlocksLargeDropdown = () => import('../../components/blocks/large-dropdown.vue' /* webpackChunkName: "components/blocks-large-dropdown" */).then(c => wrapFunctional(c.default || c))
export const BlocksNewsletterV2 = () => import('../../components/blocks/newsletter-v2.vue' /* webpackChunkName: "components/blocks-newsletter-v2" */).then(c => wrapFunctional(c.default || c))
export const BlocksNewsletter = () => import('../../components/blocks/newsletter.vue' /* webpackChunkName: "components/blocks-newsletter" */).then(c => wrapFunctional(c.default || c))
export const BlocksNutritionNewsletter = () => import('../../components/blocks/nutrition-newsletter.vue' /* webpackChunkName: "components/blocks-nutrition-newsletter" */).then(c => wrapFunctional(c.default || c))
export const BlocksProductCarousel = () => import('../../components/blocks/product-carousel.vue' /* webpackChunkName: "components/blocks-product-carousel" */).then(c => wrapFunctional(c.default || c))
export const BlocksSocialLinks = () => import('../../components/blocks/social-links.vue' /* webpackChunkName: "components/blocks-social-links" */).then(c => wrapFunctional(c.default || c))
export const BlocksThreeTwoOneUps = () => import('../../components/blocks/three-two-one-ups.vue' /* webpackChunkName: "components/blocks-three-two-one-ups" */).then(c => wrapFunctional(c.default || c))
export const BlocksVerticalScrollingSlider = () => import('../../components/blocks/vertical-scrolling-slider.vue' /* webpackChunkName: "components/blocks-vertical-scrolling-slider" */).then(c => wrapFunctional(c.default || c))
export const BlocksVisualAsset = () => import('../../components/blocks/visual-asset.vue' /* webpackChunkName: "components/blocks-visual-asset" */).then(c => wrapFunctional(c.default || c))
export const BlocksWhereToBuy = () => import('../../components/blocks/where-to-buy.vue' /* webpackChunkName: "components/blocks-where-to-buy" */).then(c => wrapFunctional(c.default || c))
export const BlocksWysiwygBlock = () => import('../../components/blocks/wysiwyg-block.vue' /* webpackChunkName: "components/blocks-wysiwyg-block" */).then(c => wrapFunctional(c.default || c))
export const GlobalBackToTopArrow = () => import('../../components/global/back-to-top-arrow.vue' /* webpackChunkName: "components/global-back-to-top-arrow" */).then(c => wrapFunctional(c.default || c))
export const GlobalLoadState = () => import('../../components/global/load-state.coffee' /* webpackChunkName: "components/global-load-state" */).then(c => wrapFunctional(c.default || c))
export const GlobalMobileChaptersMenu = () => import('../../components/global/mobile-chapters-menu.vue' /* webpackChunkName: "components/global-mobile-chapters-menu" */).then(c => wrapFunctional(c.default || c))
export const GlobalMobilePageTitle = () => import('../../components/global/mobile-page-title.vue' /* webpackChunkName: "components/global-mobile-page-title" */).then(c => wrapFunctional(c.default || c))
export const GlobalVideoModalWrapper = () => import('../../components/global/video-modal-wrapper.vue' /* webpackChunkName: "components/global-video-modal-wrapper" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/layout/header.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutLoader = () => import('../../components/layout/loader.vue' /* webpackChunkName: "components/layout-loader" */).then(c => wrapFunctional(c.default || c))
export const BlocksAthletesFilter = () => import('../../components/blocks/athletes-filter/athletes-filter.vue' /* webpackChunkName: "components/blocks-athletes-filter" */).then(c => wrapFunctional(c.default || c))
export const BlocksCareersFilter = () => import('../../components/blocks/careers-filter/careers-filter.vue' /* webpackChunkName: "components/blocks-careers-filter" */).then(c => wrapFunctional(c.default || c))
export const BlocksCareersFilterResults = () => import('../../components/blocks/careers-filter/results.vue' /* webpackChunkName: "components/blocks-careers-filter-results" */).then(c => wrapFunctional(c.default || c))
export const BlocksCollageClickToPlayVideo = () => import('../../components/blocks/collage/click-to-play-video.vue' /* webpackChunkName: "components/blocks-collage-click-to-play-video" */).then(c => wrapFunctional(c.default || c))
export const BlocksCollageAsset = () => import('../../components/blocks/collage/collage-asset.vue' /* webpackChunkName: "components/blocks-collage-asset" */).then(c => wrapFunctional(c.default || c))
export const BlocksCollageColumn = () => import('../../components/blocks/collage/collage-column.vue' /* webpackChunkName: "components/blocks-collage-column" */).then(c => wrapFunctional(c.default || c))
export const BlocksCollage = () => import('../../components/blocks/collage/collage.vue' /* webpackChunkName: "components/blocks-collage" */).then(c => wrapFunctional(c.default || c))
export const BlocksDownloadsFilter = () => import('../../components/blocks/downloads-filter/downloads-filter.vue' /* webpackChunkName: "components/blocks-downloads-filter" */).then(c => wrapFunctional(c.default || c))
export const BlocksEventsListing = () => import('../../components/blocks/events-listing/events-listing.vue' /* webpackChunkName: "components/blocks-events-listing" */).then(c => wrapFunctional(c.default || c))
export const BlocksImageCarousel = () => import('../../components/blocks/image-carousel/image-carousel.vue' /* webpackChunkName: "components/blocks-image-carousel" */).then(c => wrapFunctional(c.default || c))
export const BlocksImageCarouselSlide = () => import('../../components/blocks/image-carousel/slide.vue' /* webpackChunkName: "components/blocks-image-carousel-slide" */).then(c => wrapFunctional(c.default || c))
export const BlocksMarqueeFull = () => import('../../components/blocks/marquee/full.vue' /* webpackChunkName: "components/blocks-marquee-full" */).then(c => wrapFunctional(c.default || c))
export const BlocksMarqueeSplit = () => import('../../components/blocks/marquee/split.vue' /* webpackChunkName: "components/blocks-marquee-split" */).then(c => wrapFunctional(c.default || c))
export const BlocksMarqueeStandard = () => import('../../components/blocks/marquee/standard.vue' /* webpackChunkName: "components/blocks-marquee-standard" */).then(c => wrapFunctional(c.default || c))
export const BlocksPressReleasesListing = () => import('../../components/blocks/press-releases-listing/press-releases-listing.vue' /* webpackChunkName: "components/blocks-press-releases-listing" */).then(c => wrapFunctional(c.default || c))
export const BlocksStoriesListingFeaturedCard = () => import('../../components/blocks/stories-listing/featured-card.vue' /* webpackChunkName: "components/blocks-stories-listing-featured-card" */).then(c => wrapFunctional(c.default || c))
export const BlocksStoriesListing = () => import('../../components/blocks/stories-listing/stories-listing.vue' /* webpackChunkName: "components/blocks-stories-listing" */).then(c => wrapFunctional(c.default || c))
export const BlocksStoriesListingWideCard = () => import('../../components/blocks/stories-listing/wide-card.vue' /* webpackChunkName: "components/blocks-stories-listing-wide-card" */).then(c => wrapFunctional(c.default || c))
export const BlocksSubnavLinks = () => import('../../components/blocks/subnav/links.vue' /* webpackChunkName: "components/blocks-subnav-links" */).then(c => wrapFunctional(c.default || c))
export const BlocksSubnavStandardMenu = () => import('../../components/blocks/subnav/standard-menu.vue' /* webpackChunkName: "components/blocks-subnav-standard-menu" */).then(c => wrapFunctional(c.default || c))
export const BlocksSubnav = () => import('../../components/blocks/subnav/subnav.vue' /* webpackChunkName: "components/blocks-subnav" */).then(c => wrapFunctional(c.default || c))
export const BlocksWysiwygBanner = () => import('../../components/blocks/wysiwyg-banner/wysiwyg-banner.vue' /* webpackChunkName: "components/blocks-wysiwyg-banner" */).then(c => wrapFunctional(c.default || c))
export const BlocksWysiwygButtons = () => import('../../components/blocks/wysiwyg-banner/wysiwyg-buttons.vue' /* webpackChunkName: "components/blocks-wysiwyg-buttons" */).then(c => wrapFunctional(c.default || c))
export const GlobalBlocksBlockList = () => import('../../components/global/blocks/block-list.vue' /* webpackChunkName: "components/global-blocks-block-list" */).then(c => wrapFunctional(c.default || c))
export const GlobalBlocksBlockWrapper = () => import('../../components/global/blocks/block-wrapper.vue' /* webpackChunkName: "components/global-blocks-block-wrapper" */).then(c => wrapFunctional(c.default || c))
export const GlobalBlocksBorderTexture = () => import('../../components/global/blocks/border-texture.vue' /* webpackChunkName: "components/global-blocks-border-texture" */).then(c => wrapFunctional(c.default || c))
export const GlobalBlocksResponsiveTile = () => import('../../components/global/blocks/responsive-tile.vue' /* webpackChunkName: "components/global-blocks-responsive-tile" */).then(c => wrapFunctional(c.default || c))
export const GlobalCardsActivity = () => import('../../components/global/cards/activity.vue' /* webpackChunkName: "components/global-cards-activity" */).then(c => wrapFunctional(c.default || c))
export const GlobalCardsAthlete = () => import('../../components/global/cards/athlete.vue' /* webpackChunkName: "components/global-cards-athlete" */).then(c => wrapFunctional(c.default || c))
export const GlobalCardsCardGridWrapper = () => import('../../components/global/cards/card-grid-wrapper.vue' /* webpackChunkName: "components/global-cards-card-grid-wrapper" */).then(c => wrapFunctional(c.default || c))
export const GlobalCardsCard = () => import('../../components/global/cards/card.coffee' /* webpackChunkName: "components/global-cards-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalCardsColor = () => import('../../components/global/cards/color.vue' /* webpackChunkName: "components/global-cards-color" */).then(c => wrapFunctional(c.default || c))
export const GlobalCardsDownload = () => import('../../components/global/cards/download.vue' /* webpackChunkName: "components/global-cards-download" */).then(c => wrapFunctional(c.default || c))
export const GlobalCardsEvent = () => import('../../components/global/cards/event.vue' /* webpackChunkName: "components/global-cards-event" */).then(c => wrapFunctional(c.default || c))
export const GlobalCardsFaq = () => import('../../components/global/cards/faq.vue' /* webpackChunkName: "components/global-cards-faq" */).then(c => wrapFunctional(c.default || c))
export const GlobalCardsGeneric = () => import('../../components/global/cards/generic.vue' /* webpackChunkName: "components/global-cards-generic" */).then(c => wrapFunctional(c.default || c))
export const GlobalCardsPressRelease = () => import('../../components/global/cards/press-release.vue' /* webpackChunkName: "components/global-cards-press-release" */).then(c => wrapFunctional(c.default || c))
export const GlobalCardsRecommended = () => import('../../components/global/cards/recommended.coffee' /* webpackChunkName: "components/global-cards-recommended" */).then(c => wrapFunctional(c.default || c))
export const GlobalCardsStory = () => import('../../components/global/cards/story.vue' /* webpackChunkName: "components/global-cards-story" */).then(c => wrapFunctional(c.default || c))
export const GlobalFilterBlockCategoryFilter = () => import('../../components/global/filter-block/category-filter.vue' /* webpackChunkName: "components/global-filter-block-category-filter" */).then(c => wrapFunctional(c.default || c))
export const GlobalFilterBlockFetchesEntries = () => import('../../components/global/filter-block/fetches-entries.coffee' /* webpackChunkName: "components/global-filter-block-fetches-entries" */).then(c => wrapFunctional(c.default || c))
export const GlobalListingBlockLoadsMoreListing = () => import('../../components/global/listing-block/loads-more-listing.vue' /* webpackChunkName: "components/global-listing-block-loads-more-listing" */).then(c => wrapFunctional(c.default || c))
export const GlobalListingBlockLoadsMore = () => import('../../components/global/listing-block/loads-more.coffee' /* webpackChunkName: "components/global-listing-block-loads-more" */).then(c => wrapFunctional(c.default || c))
export const GlobalListingBlockRelatedListing = () => import('../../components/global/listing-block/related-listing.vue' /* webpackChunkName: "components/global-listing-block-related-listing" */).then(c => wrapFunctional(c.default || c))
export const PagesActivitiesActivityNav = () => import('../../components/pages/activities/activity-nav.vue' /* webpackChunkName: "components/pages-activities-activity-nav" */).then(c => wrapFunctional(c.default || c))
export const PagesActivitiesTemplate = () => import('../../components/pages/activities/template.vue' /* webpackChunkName: "components/pages-activities-template" */).then(c => wrapFunctional(c.default || c))
export const PagesAthletesCustom = () => import('../../components/pages/athletes/custom.vue' /* webpackChunkName: "components/pages-athletes-custom" */).then(c => wrapFunctional(c.default || c))
export const PagesAthletesTemplate = () => import('../../components/pages/athletes/template.vue' /* webpackChunkName: "components/pages-athletes-template" */).then(c => wrapFunctional(c.default || c))
export const PagesEventsTemplate = () => import('../../components/pages/events/template.vue' /* webpackChunkName: "components/pages-events-template" */).then(c => wrapFunctional(c.default || c))
export const PagesFavoritesBar = () => import('../../components/pages/favorites/bar.vue' /* webpackChunkName: "components/pages-favorites-bar" */).then(c => wrapFunctional(c.default || c))
export const PagesFavoritesProduct = () => import('../../components/pages/favorites/product.vue' /* webpackChunkName: "components/pages-favorites-product" */).then(c => wrapFunctional(c.default || c))
export const PagesPressRelease = () => import('../../components/pages/press-releases/press-release.vue' /* webpackChunkName: "components/pages-press-release" */).then(c => wrapFunctional(c.default || c))
export const PagesStoriesCustom = () => import('../../components/pages/stories/custom.vue' /* webpackChunkName: "components/pages-stories-custom" */).then(c => wrapFunctional(c.default || c))
export const PagesStoriesTemplate = () => import('../../components/pages/stories/template.vue' /* webpackChunkName: "components/pages-stories-template" */).then(c => wrapFunctional(c.default || c))
export const PagesTowerBasicTower = () => import('../../components/pages/tower/basic-tower.vue' /* webpackChunkName: "components/pages-tower-basic-tower" */).then(c => wrapFunctional(c.default || c))
export const PagesTowerChapteredMarquee = () => import('../../components/pages/tower/chaptered-marquee.vue' /* webpackChunkName: "components/pages-tower-chaptered-marquee" */).then(c => wrapFunctional(c.default || c))
export const PagesTowerChapteredTower = () => import('../../components/pages/tower/chaptered-tower.vue' /* webpackChunkName: "components/pages-tower-chaptered-tower" */).then(c => wrapFunctional(c.default || c))
export const PagesTowerChapters = () => import('../../components/pages/tower/chapters.vue' /* webpackChunkName: "components/pages-tower-chapters" */).then(c => wrapFunctional(c.default || c))
export const SsrCarousel = () => import('../../../node_modules/vue-ssr-carousel/index.js' /* webpackChunkName: "components/ssr-carousel" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalAccordion = () => import('../../../library/components/global/accordion.vue' /* webpackChunkName: "components/library-global-accordion" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalAlgoliaSearch = () => import('../../../library/components/global/algolia-search.coffee' /* webpackChunkName: "components/library-global-algolia-search" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalButtonList = () => import('../../../library/components/global/button-list.vue' /* webpackChunkName: "components/library-global-button-list" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalCheckbox = () => import('../../../library/components/global/checkbox.vue' /* webpackChunkName: "components/library-global-checkbox" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalCountBadge = () => import('../../../library/components/global/count-badge.vue' /* webpackChunkName: "components/library-global-count-badge" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalLocaleSelector = () => import('../../../library/components/global/locale-selector.vue' /* webpackChunkName: "components/library-global-locale-selector" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalMobileMenuButton = () => import('../../../library/components/global/mobile-menu-button.vue' /* webpackChunkName: "components/library-global-mobile-menu-button" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalNewsletterForm = () => import('../../../library/components/global/newsletter-form.vue' /* webpackChunkName: "components/library-global-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalNumberInput = () => import('../../../library/components/global/number-input.vue' /* webpackChunkName: "components/library-global-number-input" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalPlaceholder = () => import('../../../library/components/global/placeholder.vue' /* webpackChunkName: "components/library-global-placeholder" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalPrice = () => import('../../../library/components/global/price.vue' /* webpackChunkName: "components/library-global-price" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalSearchBox = () => import('../../../library/components/global/search-box.vue' /* webpackChunkName: "components/library-global-search-box" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalSmartLink = () => import('../../../library/components/global/smart-link.coffee' /* webpackChunkName: "components/library-global-smart-link" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalSpacer = () => import('../../../library/components/global/spacer.vue' /* webpackChunkName: "components/library-global-spacer" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalVariantItemRow = () => import('../../../library/components/global/variant-item-row.vue' /* webpackChunkName: "components/library-global-variant-item-row" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalWysiwyg = () => import('../../../library/components/global/wysiwyg.coffee' /* webpackChunkName: "components/library-global-wysiwyg" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalButtonsBtn = () => import('../../../library/components/global/buttons/btn.vue' /* webpackChunkName: "components/library-global-buttons-btn" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalButtonsCheckoutButton = () => import('../../../library/components/global/buttons/checkout-button.vue' /* webpackChunkName: "components/library-global-buttons-checkout-button" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalButtonsPlayButton = () => import('../../../library/components/global/buttons/play-button.vue' /* webpackChunkName: "components/library-global-buttons-play-button" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalButtonsPricespiderBtn = () => import('../../../library/components/global/buttons/pricespider-btn.vue' /* webpackChunkName: "components/library-global-buttons-pricespider-btn" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalButtonsQuickAddBtn = () => import('../../../library/components/global/buttons/quick-add-btn.vue' /* webpackChunkName: "components/library-global-buttons-quick-add-btn" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalButtonsTapButton = () => import('../../../library/components/global/buttons/tap-button.vue' /* webpackChunkName: "components/library-global-buttons-tap-button" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalCarousel = () => import('../../../library/components/global/carousel/carousel.vue' /* webpackChunkName: "components/library-global-carousel" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalModalsAlert = () => import('../../../library/components/global/modals/alert.vue' /* webpackChunkName: "components/library-global-modals-alert" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalModalsConfirm = () => import('../../../library/components/global/modals/confirm.vue' /* webpackChunkName: "components/library-global-modals-confirm" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalModalsModal = () => import('../../../library/components/global/modals/modal.vue' /* webpackChunkName: "components/library-global-modals-modal" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalModalsPdpWhereToBuy = () => import('../../../library/components/global/modals/pdp-where-to-buy.vue' /* webpackChunkName: "components/library-global-modals-pdp-where-to-buy" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalModalsSubscriptionDetails = () => import('../../../library/components/global/modals/subscription-details.vue' /* webpackChunkName: "components/library-global-modals-subscription-details" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalModalsVideo = () => import('../../../library/components/global/modals/video.vue' /* webpackChunkName: "components/library-global-modals-video" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalFilterableListFilterCategory = () => import('../../../library/components/global/filterable-list/filter-category.vue' /* webpackChunkName: "components/library-global-filterable-list-filter-category" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalFilterableList = () => import('../../../library/components/global/filterable-list/filterable-list.vue' /* webpackChunkName: "components/library-global-filterable-list" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalFilterableListFilters = () => import('../../../library/components/global/filterable-list/filters.vue' /* webpackChunkName: "components/library-global-filterable-list-filters" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalFilterableListNoResults = () => import('../../../library/components/global/filterable-list/no-results.vue' /* webpackChunkName: "components/library-global-filterable-list-no-results" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalFilterableListResultsGroupHeader = () => import('../../../library/components/global/filterable-list/results-group-header.vue' /* webpackChunkName: "components/library-global-filterable-list-results-group-header" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalFilterableListResultsGroup = () => import('../../../library/components/global/filterable-list/results-group.vue' /* webpackChunkName: "components/library-global-filterable-list-results-group" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalFavoritesFavoriteIcon = () => import('../../../library/components/global/favorites/favorite-icon.vue' /* webpackChunkName: "components/library-global-favorites-favorite-icon" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalFavoritesNavIcon = () => import('../../../library/components/global/favorites/nav-icon.vue' /* webpackChunkName: "components/library-global-favorites-nav-icon" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalFavoritesProductFavorite = () => import('../../../library/components/global/favorites/product-favorite.vue' /* webpackChunkName: "components/library-global-favorites-product-favorite" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalFavoritesRemoveModal = () => import('../../../library/components/global/favorites/remove-modal.vue' /* webpackChunkName: "components/library-global-favorites-remove-modal" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalPlaceholdersPlaceholderLayout = () => import('../../../library/components/global/placeholders/_placeholder-layout.coffee' /* webpackChunkName: "components/library-global-placeholders-placeholder-layout" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalPlaceholdersCollectionLayout = () => import('../../../library/components/global/placeholders/collection-layout.vue' /* webpackChunkName: "components/library-global-placeholders-collection-layout" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalPlaceholdersDefaultLayout = () => import('../../../library/components/global/placeholders/default-layout.vue' /* webpackChunkName: "components/library-global-placeholders-default-layout" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalPlaceholdersProductCarouselLayout = () => import('../../../library/components/global/placeholders/product-carousel-layout.vue' /* webpackChunkName: "components/library-global-placeholders-product-carousel-layout" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalPlaceholdersQuickShopLayout = () => import('../../../library/components/global/placeholders/quick-shop-layout.vue' /* webpackChunkName: "components/library-global-placeholders-quick-shop-layout" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalProductBadge = () => import('../../../library/components/global/product/badge.vue' /* webpackChunkName: "components/library-global-product-badge" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalProductVariantSelector = () => import('../../../library/components/global/product/variant-selector.vue' /* webpackChunkName: "components/library-global-product-variant-selector" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalProductCardBtnContainer = () => import('../../../library/components/global/product-card/product-card-btn-container.vue' /* webpackChunkName: "components/library-global-product-card-btn-container" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalProductCard = () => import('../../../library/components/global/product-card/product-card.vue' /* webpackChunkName: "components/library-global-product-card" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalProductCardQuickShopPurchase = () => import('../../../library/components/global/product-card/quick-shop-purchase.vue' /* webpackChunkName: "components/library-global-product-card-quick-shop-purchase" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalRecommendationsCarousel = () => import('../../../library/components/global/recommendations-carousel/recommendations-carousel.vue' /* webpackChunkName: "components/library-global-recommendations-carousel" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalRecommendationsCarouselRecommendedProduct = () => import('../../../library/components/global/recommendations-carousel/recommended-product.vue' /* webpackChunkName: "components/library-global-recommendations-carousel-recommended-product" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalSearchInput = () => import('../../../library/components/global/search-input/search-input.vue' /* webpackChunkName: "components/library-global-search-input" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalSubscriptionOptIn = () => import('../../../library/components/global/subscription-opt-in/index.vue' /* webpackChunkName: "components/library-global-subscription-opt-in" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalSubscriptionCheckbox = () => import('../../../library/components/global/subscription-opt-in/subscription-checkbox.vue' /* webpackChunkName: "components/library-global-subscription-checkbox" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalVisualCraftVisual = () => import('../../../library/components/global/visual/craft-visual.coffee' /* webpackChunkName: "components/library-global-visual-craft-visual" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalVisualResponsiveVisual = () => import('../../../library/components/global/visual/responsive-visual.vue' /* webpackChunkName: "components/library-global-visual-responsive-visual" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalVisualShopifyVisual = () => import('../../../library/components/global/visual/shopify-visual.coffee' /* webpackChunkName: "components/library-global-visual-shopify-visual" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalModalsQuickShopProductDescription = () => import('../../../library/components/global/modals/quick-shop/product-description.vue' /* webpackChunkName: "components/library-global-modals-quick-shop-product-description" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalModalsQuickCart = () => import('../../../library/components/global/modals/quick-shop/quick-cart.vue' /* webpackChunkName: "components/library-global-modals-quick-cart" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalModalsQuickShop = () => import('../../../library/components/global/modals/quick-shop/quick-shop.vue' /* webpackChunkName: "components/library-global-modals-quick-shop" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalProductPurchasingAddToCartBtn = () => import('../../../library/components/global/product/purchasing/add-to-cart-btn.vue' /* webpackChunkName: "components/library-global-product-purchasing-add-to-cart-btn" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalProductPurchasingStandardPurchase = () => import('../../../library/components/global/product/purchasing/standard-purchase.vue' /* webpackChunkName: "components/library-global-product-purchasing-standard-purchase" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalProductPurchasingSubscriberPurchase = () => import('../../../library/components/global/product/purchasing/subscriber-purchase.vue' /* webpackChunkName: "components/library-global-product-purchasing-subscriber-purchase" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalProductSubscribingAddToDeliveryModal = () => import('../../../library/components/global/product/subscribing/add-to-delivery-modal.vue' /* webpackChunkName: "components/library-global-product-subscribing-add-to-delivery-modal" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalProductSubscribingSubscriptionFrequency = () => import('../../../library/components/global/product/subscribing/subscription-frequency.vue' /* webpackChunkName: "components/library-global-product-subscribing-subscription-frequency" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalProductSubscribingSubscriptionOptions = () => import('../../../library/components/global/product/subscribing/subscription-options.vue' /* webpackChunkName: "components/library-global-product-subscribing-subscription-options" */).then(c => wrapFunctional(c.default || c))
export const LibraryGlobalProductSubscribingSubscriptionRadio = () => import('../../../library/components/global/product/subscribing/subscription-radio.vue' /* webpackChunkName: "components/library-global-product-subscribing-subscription-radio" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
