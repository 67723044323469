




























































































import Carousel from 'library/components/global/carousel/carousel'
import ProductBadge from 'library/components/global/product/badge'
import ProductFavorite from 'library/components/global/favorites/product-favorite'
import ProductVideo from 'library/components/pages/product/product-video'
import { fireCarouselSlideEvent } from 'library/services/gtm/general-events'

export default

	components: {
		Carousel
		ProductBadge
		ProductFavorite
		ProductVideo
	}

	props:
		line: String
		title: String
		handle: String
		images: Array
		tags: Array
		component: String
		objectFit:
			type: String
			default: 'contain'
		arrows:
			type: Boolean
		thumbnails:
			type: Boolean
		source:
			type: String
			default: 'craft'

	data: ->
		thumbnailIndex: 0
		carouselSettings: {
			pageDots: @thumbnails
			prevNextButtons: @arrows
		}

	computed:
		primaryImage: -> @images?[0]?.image?[0]

		# Only allow favoriting if you're on the domestic site
		canFavorite: -> process.env.CART_ENABLED

	methods:

		activeThumbnail: (index) ->
			if index == @thumbnailIndex then return 'active'

		updateFeaturedImage: (index) ->
			@thumbnailIndex = index
			@$refs.mainGallery.goToIndex index

		isVideo: (item) -> item?.video?.length

		onChange: (index) ->

			# Fire Carousel Slide view GTM event
			fireCarouselSlideEvent @$GTM_COMPONENTS.PDP_MARQUEE, index + 1

			# On carousel change (ie. on swipe) update thumbnailIndex
			@thumbnailIndex = index
			# All videos: pause and rewind back to beginning
			@$refs.mainGallery?.$el?.querySelectorAll('video')?.forEach (el) ->
				el.pause()
				el.currentTime = 0

