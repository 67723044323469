import { render, staticRenderFns } from "./og-offer.vue?vue&type=template&id=51dd602c&scoped=true&lang=pug&"
import script from "./og-offer.vue?vue&type=script&lang=coffee&"
export * from "./og-offer.vue?vue&type=script&lang=coffee&"
import style0 from "library/services/ordergroove/experiences/og-styles.styl?vue&type=style&index=0&lang=stylus&"
import style1 from "./og-offer.vue?vue&type=style&index=1&id=51dd602c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51dd602c",
  null
  
)

export default component.exports