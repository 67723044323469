import { loadScript } from 'library/services/helpers'

export default

	mounted: ->
		# Load PriceSpider if it isn't loaded
		loadScript('//cdn.pricespider.com/1/lib/ps-widget.js') unless window.PriceSpider?

	methods:

		getPricespiderBtnProps: (btn) ->

			# Pricespider button
			if btn.priceSpider then return

				# Product SKU is entered into the href field.
				# Remove any preceding 'http://', 'https://', or '/'.
				'ps-sku': btn.href?.replace(/(http(s?):\/\/|\/)/, '')
				class: 'ps-widget'

			# Regular button
			return
				to: btn.href
