


















































import ResponsiveTile from './responsive-tile'
import BorderTexture from './border-texture'
import { isDark } from 'library/services/helpers'
export default

	components: { ResponsiveTile, BorderTexture }

	inject: tower: default: type: 'towers'

	provide: ->
		blockIndex: @index
		darkWrapper: @isDarkBackground
		themeOverride: @blockThemeOverride

	props:
		block: Object
		index: Number
		noGutter: Boolean

	computed:

		# Get the wrapper object
		wrapper: -> @block.wrapper?[0] || {}

		# Set styles based on props
		classes: -> [
			"padding-top-#{top}" if top = @wrapper.verticalPadding?[0]?.top
			"padding-bottom-#{bottom}" if bottom = @wrapper.verticalPadding?[0]?.bottom
			"margin-top-#{top}" if top = @wrapper.negativeMargin?[0]?.top
			"margin-bottom-#{bottom}" if bottom = @wrapper.negativeMargin?[0]?.bottom
			'overlap-sidebar' if @isChapteredTower and @block.fullWidth
			'respect-sidebar' if @isChapteredTower and not @block.fullWidth
			'inverted-ui' if @isDarkBackground
			'elevate-slot' if @block.wrapper?.length or @hasIndentedBackground
			'max-w-narrow' if @wrapper.wrapperWidthConstraint
			'deltas-' + @$kebab @wrapper.deltasIconLayout if @hasDeltasIcon
		]

		# Is the background color dark
		isDarkBackground: -> @hasBackground and isDark @wrapper.backgroundColor

		# Block theme overriding
		blockThemeOverride: -> @wrapper.themeOverride

		blockWidth: ->
			return if @noGutter
			switch @block.maxWidth
				when 'full' then 'max-w-full'
				when 'wide' then 'max-w-wide'
				when 'normal' then 'max-w'
				when 'narrow' then 'max-w-narrow'
				when 'article' then 'max-w-article'
				else 'max-w-wide'

		# Break out of chaptered tower columns
		isChapteredTower: -> @tower.type == 'chaptered'

		# Passthrough styles from the background
		styles: ->
			color: @wrapper.textColor
			backgroundColor: @wrapper.backgroundColor

		# Does the background repeat
		isRepeating: -> !!@wrapper.backgroundStyle?.match /^repeat/

		# Make simple border texture accessor
		borderTexture: -> @wrapper.borderTexture?[0] || {}

		# Map the placements for easier rendering
		imagePlacements: -> (@wrapper.imagePlacements || []).map (placement) -> {
			...placement
			style:
				top: defaultUnit placement.top
				left: defaultUnit placement.left
		}

		# Was a background specified
		hasBackground: ->
			image = @wrapper.backgroundImage?[0] || {}
			@wrapper.backgroundColor or image.desktop or image.mobile

		# Apply indented default background on chaptered tower pages that don't
		# have a background image
		hasIndentedBackground: -> @isChapteredTower and not @hasBackground

		# Are there delta icons specified
		hasDeltasIcon: ->
			@wrapper.deltasIconLayout and
			@wrapper.deltasIconLayout != 'none'

# Convert to px if no unit specified
defaultUnit = (val) -> if val.match /^[\-\d]+$/ then val + 'px' else val

