import Vue from 'vue'
import { wait } from './function.coffee'

# Mount a component on the body, like a modal, and return the mounted component
# instance. The "component" argument should be a Vue component instance, like
# returned from importing a single file component.
export mountOnBody = (component, options = {}) ->
	appendMount document.body, component, options

# Helpers to prepend or append a component
export appendMount = (element, component, options = {}) ->
	mountInside element, component, options, true
export prependMount = (element, component, options = {}) ->
	mountInside element, component, options, false

# Mount a component within an element
mountInside = (
	element,
	component,
	options = {},
	append = true) -> new Promise (resolve) ->

	# Ensure there is a parent, waiting if need be
	unless options.parent
	then await new Promise (resolve) ->
		setDefaultParent = ->
			options.parent = window.$nuxt?.$root ||
				window['$layout-header'] # Comes from shared-intiialization
			if options.parent then resolve() else wait 50, setDefaultParent
		setDefaultParent()

	# Mount the component
	attachMethod = if append then 'appendChild' else 'prepend'
	vm = new (Vue.extend component)(options)
	vm.$mount()
	element[attachMethod](vm.$el)
	resolve vm

# Add mixins at the app level
# https://github.com/nuxt/nuxt.js/issues/1593#issuecomment-384554130
export extendApp = (app, mixin) ->
	app.mixins = [] unless app.mixins
	app.mixins.push mixin

# Open the cart
import CartFlyout from 'library/components/layout/cart-flyout/cart-flyout'
export openCart = ->
	return if document.querySelector '.cart-flyout'
	mountOnBody CartFlyout
