












import once from 'lodash/once'
import { mountOnBody } from 'library/services/helpers'
import UpsellSuccess from 'library/components/global/modals/og-upsell-success'
export default

	props:
		variantId: String # Base64ed Shopify ID
		successEvent: Function
		pollRate:
			type: Number
			default: 200 # Rate that the DOM is read for OG state

	components: {
		UpsellSuccess
	}

	data: ->
		canRender: not process.env.IS_NUXT # Requires Shopify App
		offerState: null

	# Setup polling for OG state. Mutation observer didn't work nor did writing
	# script tags form the OG Experience markup that woudld emit events or
	# call window methods
	mounted: ->
		return unless @canRender
		@$defer @listenForUpsell # Wait for canRender to take effect
		@ogDomStateInterval = setInterval @setOfferState, @pollRate

	beforeDestroy: ->
		clearInterval @ogDomStateInterval
		@upsellBtn?.removeEventListener 'click', @successfulUpsell

	watch:

		# Update parent with the offer state / subscription mode
		offerState: (val) -> @$emit 'state', val

	methods:

		# Set the mode that OG is operating by detecting elements in the markup
		setOfferState: ->
			@offerState = switch
				when @elExists '[data-offer-state="optin"]' then 'optin'
				when @elExists '[data-offer-state="upsell"]' then 'upsell'
				when @elExists '[data-offer-state="exists"]' then 'exists'
				else null

		# Quick helper to test if an element exists by checking if it has some
		# height.
		elExists: (selector) -> !!@$el?.querySelector(selector)?.clientHeight

		# Listen for upsell modal to get closed
		listenForUpsell: ->
			@upsellBtn = @$el.querySelector 'og-upsell-modal [slot="confirm"] .btn'
			unless @upsellBtn then return @$wait @pollRate, @listenForUpsell
			@upsellBtn.addEventListener 'click', @successfulUpsell

		# Open confirmation modal
		successfulUpsell: ->
			@$emit 'upsell'
			mountOnBody UpsellSuccess, parent: this

