



















export default

	inject:

		# The theme of the tower, which can add another dimension to the styling
		# May be: "default", "clif", "luna", or "kid"
		tower:
			default: theme: 'default'

		# The theme of a block theme
		blockTheme:
			from: 'themeOverride'
			default: 'default'

	props:

		type:
			type: String
			default: 'button'
			validator: (val) -> val in ['cta', 'button', 'text', 'back']

		# The theme effects the coloring of the button
		theme:
			type: String
			default: 'primary'
			validator: (val) ->
				val in ['primary', 'secondary', 'utility', 'outline', 'inverted', 'white', 'medium-grey']

		# Whether to ignore the tower theme, used on add to cart btns
		ignoreTowerTheme: Boolean

		# Make the button "greyed out" and non-interactive
		disabled: Boolean

		# Show a loading state and make non-interactive
		loading: Boolean

		# Role
		role: String

		# Tab index
		tabindex: String

		# Boolean to make buttons a submit button
		submit: Boolean

		# Creates an anchor with this value as an href
		link: String

	computed:

		# Make the class list for the button
		classes: -> [
			"type-#{@type}"
			"theme-#{@theme}"
			"tower-theme-#{@towerTheme}"
			disabled: @disabled
			loading: @loading
			'non-interactive': @nonInteractive
		]

		# Make tower theme class
		towerTheme: ->
			if @ignoreTowerTheme then 'default'
			if @blockTheme? && @blockTheme != 'default' then @blockTheme
			else @tower.theme

		# States that should supress clicks
		nonInteractive: -> @disabled or @loading

		# Button HTML tag
		tag: ->
			return 'smart-link' if @link
			return 'button' if @type == 'button'
			'div'

		# Button role
		btnRole: ->
			if @role then @role
			else 'button'

		# If a submit button, specify that type
		buttonType: ->
			return '' unless @tag == 'button'
			if @submit then 'submit' else 'button'

	methods:

		# Prevent clicking if non-interactive
		onClick: (e) ->
			if @nonInteractive
			then e.preventDefault()
			else @$emit 'click'

