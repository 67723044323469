



































































import cartData from 'library/mixins/cart-data'
import Price from 'library/components/global/price'
import { FREE_SHIPPING_THRESHOLD } from 'library/constants'
export default

	mixins: [ cartData ]

	components:
		Price: Price

		# Prevent issues with recursion when quick-shop launched from a
		# recommendations carousel.
		RecommendationsCarousel: -> import('library/components/global/recommendations-carousel/recommendations-carousel')

	props:
		product: Object
		line: Object

	computed:

		freeShippingThresholdAmount: ->
			if !@hasFreeShipping
			then FREE_SHIPPING_THRESHOLD - @subtotal else 0

		# Percentage of the free shipping progress bar.
		freeShippingThresholdPercentage: -> if !@hasFreeShipping then 100 - Number((@freeShippingRemainingAmount / FREE_SHIPPING_THRESHOLD) * 100).toFixed(1) else 0

		# CSS Styles for the free shipping progress bar
		freeShippingBarStyles: ->
			width: "#{@freeShippingThresholdPercentage}%"

		addedText: ->
			if @line.quantity > 1 then @$t('cart.items')
			else @$t('cart.item')

		count: -> @$store.getters['cart/itemCount']

		frequency: ->
			return unless sellingPlan = @line?.sellingPlanAllocation?.sellingPlan
			matches = sellingPlan?.name?.match /^Delivered every[\w ]*days?/
			return matches[0] if matches

		featuredImage: -> @line.variant.featuredImage || @product.images[0]

	methods:

		# Fire 'View Cart Page' GTM event
		onViewCart: ->
			@$gtm.push
				event: @$GTM_EVENTS.VIEW_CART_PAGE_CLICKED
				component: @$GTM_COMPONENTS.QUICK_SHOP_MODAL

		# Fire 'Checkout clicked' GTM event
		onCheckout: ->
			@$gtm.push
				event: @$GTM_EVENTS.CHECKOUT_CLICKED
				component: @$GTM_COMPONENTS.QUICK_SHOP_MODAL

