
















export default
	data: ->
		value: ''
		isEmpty: true

	watch:
		value: (value) ->
			if value == '' && !@isEmpty
				@isEmpty = true
			else if value != '' && @isEmpty
				@isEmpty = false

	methods:
		submit: ->
			if @value==''
				return
			console.log 'TODO: submit discount code...', @value


