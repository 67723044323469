






















export default

	props:

		# Is the product available
		available: Boolean

		# Trigger loading state
		loading: Boolean

	computed:

		# Default cta message
		defaultCta: -> if @available then 'Add to Cart' else 'Unavailable'

