/**
 * Settings and configs that are shared in multiple places
 */

// Shopfiy API version to use. If changing, also update constants.cjs
export const SHOPIFY_API_VERSION = '2023-10'

// The root paths that are handled by Shopify. These get auto-redirected to.
export const shopifyRootPaths = [
	'cart',
	'account',
	'collections',
	'products',
	'pages',
	'password',
	'challenge',
]

// Make a regex that tests whether the current path matches a Shopify route.
// This includes the checkouts and order detail URLs whose first segment is
// not a keyword
// https://regex101.com/r/Ags3LG/1
export const isShopifyRegex = new RegExp(`^\/(${shopifyRootPaths.join('|')})|([^\/]+\/(checkouts|orders))`, 'i')

// Errors to not report
export const sentryIgnoreErrors = [
	'Network Error', // Suspected to be network connectivity based
]

// Cookie name for the old checkout api id and subscriptions
export const CHECKOUT_KEY = 'checkoutId'
export const SUBSCRIPTIONS_KEY = 'subscriptions'

// Cookie name for the storefront api cart id
export const CART_ID_KEY = 'cartId'

// Cookie name for Cart Count
export const CART_COUNT_KEY = 'cartCount'

// Bundle Attribute Key
export const BUNDLE_ATTRIBUTE_KEY = 'Bundle'
export const BUNDLES_CART_ATTRIBUTE_KEY = 'Bundles'

// Cookie name for newsletter signup email
export const NEWSLETTER_SIGNUP_KEY = 'newsletter_signup'

// Cookie name for favorites array
export const FAVORITES_KEY = 'session_favorites'

// Cookie name for the customer access token
export const CUSTOMER_ACCESS_TOKEN = 'customer_access_token'

// Address field constraints
export const ADDRESS_CHAR_LIMIT = 40
export const CITY_CHAR_LIMIT = 25

export const FREE_SHIPPING_THRESHOLD = 40

// GTM events name
export const GTM_EVENTS = {
	ACCOUNT_NAV_CLICK: 'Account Nav Click',
	ADD_TO_CART: 'Add To Cart',
	ADD_TO_SUBSCRIPTION: 'Add To Subscription',
	CAROUSEL_SLIDE_VIEW: 'Carousel Slide View',
	CAROUSEL_VIDEO_VIEW: 'Carousel Video View',
	CART_DETAILS: 'cartDetails',
	CHECKOUT_CLICKED: 'Checkout Clicked',
	COLLECTION_FILTER: 'Collection Filter',
	COLLECTION_VIEW: 'collectionView', // Used for SitRep GTM Event
	CREATE_ADDRESS: 'Create Address',
	DELETE_ADDRESS: 'Delete Address',
	EDIT_ADDRESS: 'Edit Address',
	EXPAND_ACCODRION: 'Expand Accordion',
	FAVORITE: 'Favorite',
	FAVORITES_HYDRATED: 'Favorites Hydrated',
	IDENTIFY_CUSTOMER: 'Identify Customer',
	LOGIN_CLICK: 'Login Click',
	LOGIN_SUBMIT: 'Login Submit',
	LOGOUT_CLICK: 'Logout Click',
	NEWSLETTER_SUBMIT: 'Newsletter Sign Up',
	OG_MSI_CLICK: 'OG MSI Click', // Ordergroove manage subscriptions interface
	OPEN_SIDEBAR: 'Open Sidebar',
	PAGE_MOUNTED: 'Page Mounted',
	PAGINATION_BACK: 'Pagination Back',
	PAGINATION_NEXT: 'Pagination Next',
	PASSWORD_RESET_SUBMIT: 'Password Reset Submit',
	REGISTER_CLICK: 'Register Click',
	REGISTER_SUBMIT: 'Register Submit',
	SAVE_ADDRESS: 'Save Address',
	SEARCH_RESULT_CLICKED: 'Search Result Clicked',
	SEARCH_TERM: 'Search Term',
	SELECT_PURCHASE_TYPE_ONE_TIME: 'Purchase Type - One Time',
	SELECT_PURCHASE_TYPE_SUBSCRIPTION: 'Purchase Type - Subscription',
	UNFAVORITE: 'Unfavorite',
	UPDATE_ACCOUNT_SETTINGS: 'Update Account Settings',
	UPDATE_CART: 'Update Cart',
	UPDATE_SHOP_PAY: 'Update Shop Pay',
	UPDATE_SUBSCRIPTION_PAYMENT: 'Update Subscription Payment',
	VIEW_CART_PAGE_CLICKED: 'View Cart Page Clicked',
	VIEW_CART_PAGE: 'View Cart Page',
	VIEW_COLLECTION: 'View Collection',
	VIEW_QUICK_SHOP: 'View Quick Shop',
	VIEW_PDP: 'View Product',
	VISUAL_ASSET_CLICK: 'Visual Asset Click',
}

// GTM components name
export const GTM_COMPONENTS = {
	ACCOUNT_DASHBOARD: 'Account Dashboard',
	ACCOUNT_FLYOUT: 'Account Flyout',
	ACCOUNT_LAYOUT: 'Account Layout',
	ACCOUNT_SETTINGS_PAGE: 'Account Settings Page',
	ADDRESS_MANAGEMENT_PAGE: 'Address Management Page',
	BROWSE_ALL_FLAVORS: 'Browse All Flavors',
	CARD_CAROUSEL: 'Card Carousel',
	CART_PAGE: 'Cart Page',
	COLLAGE_CAROUSEL: 'Collage Carousel',
	FAVORITES_FLYOUT: 'Favorites Flyout',
	FAVORITES_PAGE: 'Favorites Page',
	FLYOUT_CART: 'Flyout Cart',
	HEADER_GUEST_TOOLTIP: 'Header Guest Tooltip',
	IMAGE_CAROUSEL: 'Image Carousel',
	LOGIN_PAGE: 'Login Page',
	ORDERS_PAGE: 'Orders Page',
	PASSWORD_RESET_PAGE: 'Password Reset Page',
	PAYMENT_METHOD_PAGE: 'Payment Method Page',
	PDP_MARQUEE: 'PDP Marquee',
	PRODUCT_CARD: 'Product Card',
	PRODUCT_CAROUSEL: 'Product Carousel',
	PRODUCT_FEATURES: 'Product Features',
	QUICK_SHOP_MODAL: 'Quick Shop Modal',
	QUICK_SHOP_RECOMMENDATIONS: 'Quick Shop Recommendations',
	REGISTER_PAGE: 'Register Page',
	SUBSCRIPTION_MANAGEMENT_PAGE: 'Subscription Management Page',
	YMAL: 'You May Also Like',
}

// GA search_category values
export const SEARCH_CATEGORIES = {
	CONTACT: 'contact',
	PRODUCT: 'product',
	SITE: 'site',
}

// Out of stock tag regex
export const outOfStockRegex = new RegExp('out of stock', 'gi')

// Constants related storing inactive subscription ids on customer metafields
export const INACTIVE_SUBSCRIPTION_METAFIELD_NAMESPACE = 'ordergroove',
	INACTIVE_SUBSCRIPTION_METAFIELD_KEY = 'removed_inactive_subscription_ids'

