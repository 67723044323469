###
Keeps tab focus inside of a modal or popup
###
export default

	data: ->
		# all general focusable elements
		focusableElements: 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), .smart-nav-link'
		focusModal: null

	methods:

		# Kick off focus
		initFocus: ->
			@$nextTick ->
				@focusModal = @$el
				@initModalFocus()

		# init the focus modal and set up the elements to listen to
		initModalFocus: ->
			# Get the elements to determine first and last
			focusableContent = @focusModal.querySelectorAll(@focusableElements)
			firstFocusableElement = focusableContent[0]
			lastFocusableElement = focusableContent[focusableContent.length - 1]

			@setListeners(firstFocusableElement, lastFocusableElement)

		# Set the listener for tab press
		setListeners: (firstElement, lastElement) ->
			document.addEventListener 'keydown', @tabElements(firstElement, lastElement)
			firstElement.focus()

		# Cycle through elements
		tabElements: (e, firstElement, lastElement) ->
			tabHit = e.key == 'Tab' or e.keyCode == 9
			if !tabHit then return

			if e.shiftKey
				if document.activeElement == firstElement
					lastElement.focus()
					e.preventDefault()

			else
				if document.activeElement == lastElement
					firstElement.focus()
					e.preventDefault()

	destroyed: -> document.removeEventListener 'keydown', @tabElements
