import { isShopifyUrl, makeShopifyUrl, isNuxtUrl, makeNuxtUrl
} from 'library/services/helpers'

# Render HTML content from craft
export default
	name: 'WYSIWYG'
	functional: true

	props:
		html: String # Pass the HTML in
		tag:
			type: String
			default: 'div' # You might override for blockquote

	# Render a div with wsywiyg class and props
	render: (create, { props, data }) ->
		return unless props.html
		create 'div', {
			...data

			# Automatically parse anchors
			directives: [ { name: 'parse-anchors' } ]

			# Append the WYSIWYG class
			staticClass: ['wysiwyg', data.staticClass].join(' ').trim()

			# Render the HTML
			domProps: innerHTML: wrapTables smartenLinks props.html
		}

# Add domain when switching between apps.
smartenLinks = (html) ->
	html.replace /href=['"]([^'"]+)['"]/ig, (matched, url) ->
		url = do ->

			# We're on Shopify and this is a Nuxt URL (Only the Shopify app has a
			# NUXT_APP_URL env var) so add Nuxt hostname
			if !process.env.IS_NUXT and isNuxtUrl url
				return makeNuxtUrl url

			# We're on Nuxt and this is a Shopify URL
			if process.env.IS_NUXT and isShopifyUrl url
				return makeShopifyUrl url

			# Else, don't touch
			return url

		# Return HTML attribute
		return "href='#{url}'"

# Add a wrapping div around HTML instances so they can be horizontally scrolled
wrapTables = (html) ->
	html
	.replace(/<table>/g, '<div class="table-wrap"><table>')
	.replace(/<\/table>/g, '</table></div>')
