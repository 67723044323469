import { BUNDLE_ATTRIBUTE_KEY } from 'library/constants'
import {
	makeBundlePrice
	makeBundleCompareAtPrice
	makeBundleVariantTitle
} from './bundlingHelpers'
import { twoDecimals } from 'library/services/helpers/number'
import { makeShopifyGid, getShopifyId } from 'library/services/helpers/id'
import { productNumberFromSku } from 'library/services/helpers/product'

# Get the list of line items, filtering out hidden items like free gifts
# Group bundles into their respective line item
export reduceBundleLines = ({ lineItems, allBundles }) ->
	lineItems.reduce (lines, line) ->

		# Return early if line isn't a bundle
		unless bundleSku = getLineBundleSku line
		then return [...lines, line]

		# Return early if this bundle has already been added to the lines
		return lines if lines.find (line) -> line.bundleSku == bundleSku

		# Lookup bundle in globals
		return lines unless bundle = allBundles.find (bundle) ->
			bundle.sku == bundleSku

		# Collect all the variants of this bundle from the lines
		bundleLines = lineItems.filter (line) ->
			line.attributes.some ({ key, value }) ->
				key == BUNDLE_ATTRIBUTE_KEY && value == bundleSku

		# Calculate values
		subtotalAmount = calculateCost bundleLines, 'subtotalAmount'
		totalAmount = calculateCost bundleLines, 'totalAmount'
		quantity = calculateLineQuantity bundleLines, bundle
		variantTitle = makeVariantTitle bundleLines, bundle

		# The final price is the price of a unit with discounts applied. This is
		# used in some GTM tags
		# https://shopify.dev/docs/api/liquid/objects/line_item#line_item-final_price
		finalPrice = totalAmount / quantity

		# Append the new line to the list of all lines
		[ ...lines, {
			id: bundle.sku
			bundleVariants: bundle.bundleVariants
			bundleId: bundle.id
			bundleSku: bundle.sku
			bundleLines
			quantity
			finalPrice: twoDecimals finalPrice # Deprecated
			cost:
				subtotalAmount: amount: twoDecimals subtotalAmount
				totalAmount: amount: twoDecimals totalAmount
			variant:
				# Not using the bundle's variant id because that could be misleading
				# because we're not *actually* using that variant during checkout.
				id: bundle.sku
				sku: bundle.sku
				productNumber: productNumberFromSku bundle.sku
				price: bundle.price
				compareAtPrice: bundle.compareAtPrice
				title: variantTitle
				image: bundle.image
				product:
					id: bundle.id
					title: bundle.title
					handle: bundle.handle
					productType: bundle.productType
					vendor: bundle.vendor
					tags: bundle.tags
		}]
	, []

# Get the bundle sku from a line, if the line is participating in a bundle
export getLineBundleSku = (line) ->
	if bundleAttribute = line.attributes.find ({ key }) ->
		key == BUNDLE_ATTRIBUTE_KEY
	then return bundleAttribute.value

# Sum up the costs of all bundle linest
calculateCost = (bundleLines, type = 'subtotalAmount') ->
	bundleLines.reduce (sum, line) ->
		sum + Number line.cost?[type]?.amount
	, 0

# Calculate the quantity of the bundle by comparing the quantiy of the
# first bundleLine with the count of that bundleVariant. The quantity is
# quantity of the line divided by that count
calculateLineQuantity = (bundleLines, bundle) ->
	count = getBundleVariantCount bundle, bundleLines[0].variant.id
	return bundleLines[0].quantity / count

# Make the bundle by simulating the data needed by the makeBundleVariantTitle
# helper
makeVariantTitle = (bundleLines, bundle) ->
	makeBundleVariantTitle bundleLines.map ({ variant }) ->
		title: variant.title
		count: getBundleVariantCount bundle, variant.id

# Get the count of the first line variant that is included in the bundle
getBundleVariantCount = (bundle, variantId) ->
	{ count } = bundle.bundleVariants.find (metafieldBundleVariant) ->
		getShopifyId(metafieldBundleVariant.variantId) == getShopifyId(variantId)
	return count

# Remove bundling properties from a line item, like for when lines are passed to
# GTM after being rolled up. I'm trying to avoid using lodash/pickBy here. For
# example, this removes the `bundleLines` property that was added by
# reduceBundleLines.
export removeBundleDataFromLineItem = (lineItem) ->
	entries = Object.entries(lineItem).reduce (entries, [ key, val ]) ->
		return entries if key.startsWith 'bundle'
		return [...entries, [key, val ]]
	, []
	return Object.fromEntries entries
