import { loadScript } from 'library/services/helpers'

export initRecaptchaListener = () -> 

	# Self removed funcs
	registerInteractions = () -> 
		loadScript('https://www.google.com/recaptcha/api.js?render=' + process.env.RECAPTCHA_SITE_KEY)
		document.body.removeEventListener 'click', registerInteractions
		document.body.removeEventListener 'keyup', registerInteractions

	# Listen for interactions and update the store
	document.body.addEventListener 'click', registerInteractions
	document.body.addEventListener 'keyup', registerInteractions