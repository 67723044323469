import * as cartApi from 'library/services/shopify/cart'
import { migrateCheckoutCart } from 'library/services/shopify/cart-migrator'
import {
	BUNDLE_ATTRIBUTE_KEY
	CART_COUNT_KEY
	CART_ID_KEY
} from 'library/constants'
import { applySubscriptionDiscount } from 'library/services/ordergroove/helpers'
import {
	EV_DISCOUNT_PERCENTAGES
	EV_CODE_CART_ATTRIBUTE_KEY
} from 'library/services/expert-voice/expert-voice-constants'
import { reduceBundleLines } from 'library/services/shopify/bundling/rollupLines'
import { cookie, wait } from 'library/services/helpers'

# Make the client client side
export getCart = ->

	# Lookup cart if there is an existing cart id
	cart = if cartId = cookie.get CART_ID_KEY
	then await cartApi.fetch cartId

	# Check to see if old API cart exists (removing it in the process)
	cart = await migrateCheckoutCart() unless cart

	# Create a new cart if there was no cart id or we couldn't get a valid cart
	cart = await cartApi.create() unless cart

	# Update the cookie expiration
	cookie.set CART_ID_KEY, cart.id, expires: 14 # Days

	# Return the cart
	return cart

# Stores the Shopify cart state
# https://shopify.dev/docs/ajax-api/reference/cart#get-cart-js
export state = ->
	# Default values for Shopify properties we consume
	id: null
	lineItems: []
	cost:
		subtotalPrice: amount: 0
		totalPrice: amount: 0
		totalTaxPrice: amount: 0
	discountCodes: []
	attributes: []
	buyerIdentity: customer: id: null
	checkoutUrl: '' # The checkout URL

	# App-specific cart values
	open: false # The open/close state of the flyout
	hydrating: false # Whether we're started hydrating
	hydrated: false # Whether we've fetched the cart yet
	recommendations: [] # Fetched via cart-recommendations service

export getters =

	# Filter and reduce line items for display in the cart
	lineItems: (state, getters, rootState, rootGetters) ->

		# Filter out hidden items like free gifts
		lineItems = state.lineItems.filter (item) ->
			return unless item.variant # Maye not exist if variant is a draft
			item.variant.product.handle not in getters.hiddenCartItemHandles

		# Rollup lines that are part of a bundle
		allBundles = rootState.bundles.bundles
		return reduceBundleLines { lineItems, allBundles }

	# Get all the hidden items from automatic cart items
	hiddenCartItemHandles: (state, getters, rootState) ->
		(rootState.globals.automaticCartItems?.conditions || [])
		.filter (condition) -> condition.hideWithinCart
		.map (condition) -> condition.productHandle

	# Get the quantity of items in the cart
	itemCount: (state, getters) ->
		unless state.hydrated
		then return parseInt(cookie.get CART_COUNT_KEY) || 0
		getters.lineItems.reduce (sum, lineItem) ->
			sum + lineItem.quantity
		, 0

	# Get the cart subtotal
	subtotal: (state, getters) -> parseFloat state.cost.subtotalPrice.amount

	# Get the cart total
	total: (state, getters) -> parseFloat state.cost.totalPrice.amount

	# If there are subscriptions, redirect to cart merge script page
	checkoutUrl: (state, getters) ->
		if getters.hasSubscriptions
		then "#{process.env.SHOPIFY_URL}/pages/subscription"
		else state.checkoutUrl

	# List of subscriptions
	subscriptions: (state, getters) -> getters.lineItems.filter (item) ->
		!!item?.sellingPlanAllocation

	# Does the cart have subscriptions
	hasSubscriptions: (state, getters) -> getters.subscriptions.length > 0

	# Get the EV cart discount value
	evDiscount: (state) ->
		if codeAttribute = state.attributes.find ({ key }) ->
			key == EV_CODE_CART_ATTRIBUTE_KEY
		then EV_DISCOUNT_PERCENTAGES[codeAttribute.value] || 0
		else 0

export mutations =

	# Replace the current cart with the new one
	replace: (state, cart) -> Object.assign state, cart

	# Mark has hydrated
	isHydrating: (state) -> state.hydrating = true
	isHydrated: (state) -> state.hydrated = true

export actions =

	# Add an item to the cart
	addItem: (
		{ state, dispatch, commit },
		{ variantId, quantity = 1, sellingPlanId, attributes }
	) ->
		try
			await dispatch 'fetchUnlessHydrated'
			cart = await cartApi.addVariant {
				cartId: state.id
				variantId
				quantity: parseInt quantity
				sellingPlanId
				attributes
			}
			commit 'replace', cart
		catch e then console.error e

	# Add a bundle to the cart by adding all of it's child variants
	# Expects:
	#		bundleVariants to be an array with the following structure:
	# 	[
	# 		{
	# 			variantId
	# 			quantity
	# 			sellingPlanId (if applicable)
	# 		}
	# 	]
	# quantity is to be part of the bundleVariants so bundles with
	# different counts can be created
	addBundle: (
		{ state, dispatch, commit },
		{ bundleVariants, bundleSku, quantity = 1 }
	) ->
		lines = bundleVariants.map ({ variantId, count }) -> {
			variantId
			quantity: quantity * count
			attributes: {
				[BUNDLE_ATTRIBUTE_KEY]: bundleSku
			}
		}

		try
			await dispatch 'fetchUnlessHydrated'
			cart = await cartApi.addLines {
				cartId: state.id
				lines
			}
			commit 'replace', cart
		catch e then console.error e

	# Change an item's quantity
	updateItem: (
		{ state, commit, getters, dispatch },
		{ lineId, quantity, sellingPlanId }
	) ->
		await dispatch 'fetchUnlessHydrated'

		# Lookup the referenced line and, if it's a bundle, pass off to our other
		# update. The line may not be found if it's a line that's hidden by the
		# getter, like a free gift.
		if (line = getters.lineItems.find (line) -> line.id == lineId) and
			line.bundleLines?.length > 0
		then return await dispatch 'updateBundleLine', { lineId, quantity }

		if !quantity
			cart = await cartApi.deleteLine {
				cartId: state.id
				lineId
				sellingPlanId
			}
		else
			cart = await cartApi.updateLine {
				cartId: state.id
				lineId
				quantity
				sellingPlanId
			}
		commit 'replace', cart

	# Update a bundle line using the lineIds of the real, hidden lines that
	# compose the bundle
	updateBundleLine: (
		{ state, commit, getters, dispatch },
		{ lineId, quantity, sellingPlanId }
	) ->
		await dispatch 'fetchUnlessHydrated'

		# Lookup the referenced line and, if it's a bundle, pass off to our other update
		line = getters.lineItems.find (line) -> line.id == lineId

		if !quantity
			cart = await cartApi.deleteLines {
				cartId: state.id
				lineIds: line.bundleLines.map (line) -> line.id
			}
		else

			getLineCount = (cartLine) =>
				variantId = cartLine.variant.id
				bundleLine = line.bundleVariants.find (bundleVariant) =>
					bundleVariant.variantId == variantId
				bundleLine.count

			lines = line.bundleLines.map (line) ->
				{
					lineId: line.id
					quantity: getLineCount(line) * quantity
					attributes: line.attributes
				}

			cart = await cartApi.updateLines {
				cartId: state.id
				lines
			}

		commit 'replace', cart

	# Item Update method does not work with a line that has an invalid line
	# since it uses store getters rather than the true store state.
	# This method uses state to force and update and removal of a line.
	removeInvalidItems: (
		{ state, commit, dispatch },
		{ lineIds }
	) ->
		await dispatch 'fetchUnlessHydrated'

		# Filter to just lineIds in the cart
		filteredLineIds = lineIds.filter (lineId) -> state.lineItems.some (line) ->
			line.id == lineId

		# Delete the line from our cart
		cart = await cartApi.deleteLines {
			cartId: state.id
			lineIds: filteredLineIds
		}

		# Replace our cart store
		commit 'replace', cart

	# Fetch the current cart status or start a new cart
	fetch: ({ commit, state }) ->
		# We should never try to fetch unless the cart is enabled
		return unless process.env.CART_ENABLED
		commit 'isHydrating' unless state.hydrating
		try cart = await getCart()
		catch e
			cookie.remove CART_ID_KEY
			cart = await getCart()
		commit 'replace', cart
		commit 'isHydrated' unless state.hydrated
		return cart

	# Fetch the cart unless it is already hydrated
	fetchUnlessHydrated: ({ state, dispatch, }) -> switch
		when state.hydrated then return state
		when state.hydrating then return new Promise (resolve) ->
			checkIfHydrated = ->
				if state.hydrated then resolve state
				else wait 50, checkIfHydrated
			wait 50, checkIfHydrated
		else return dispatch 'fetch'

	# Replace the checkout object with a freshly fetched version
	refresh: ({ state, commit }) ->
		return unless state.id
		commit 'replace', await cartApi.fetch state.id

	# Subscription Actions; Mapped subscription functions to replace legacy
	# Orgergroove mixin calls and references

	# Add a subscription to the cart; maps the legacy payload to addItem
	addSubscription: ({ dispatch }, payload) ->
		dispatch 'addItem', payload

	# Map the legacy updateSubscription method
	updateSubscription: ({ dispatch }, payload) ->
		dispatch 'updateItem', payload

	# Update or remove a subscription from the cart
	updateSellingPlan: ({ state, dispatch }, payload) ->
		{ quantity } = state.lineItems.find ({ id }) -> id == payload.lineId
		dispatch 'updateItem', { ...payload, quantity }

	# Remove the selling plan from a line item by deleting item and replacing with
	# base item
	removeSellingPlan: ({ state, dispatch }, payload) ->
		{ quantity } = state.lineItems.find ({ id }) -> id == payload.lineId
		dispatch 'updateItem', { ...payload, quantity, sellingPlanId: null }
