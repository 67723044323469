import { render, staticRenderFns } from "./og-upsell-success.vue?vue&type=template&id=a164ea14&scoped=true&lang=pug&"
import script from "./og-upsell-success.vue?vue&type=script&lang=coffee&"
export * from "./og-upsell-success.vue?vue&type=script&lang=coffee&"
import style0 from "./og-upsell-success.vue?vue&type=style&index=0&id=a164ea14&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a164ea14",
  null
  
)

export default component.exports