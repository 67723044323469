





























import RemoveModal from './remove-modal'
import { mountOnBody } from 'library/services/helpers'
import FavoriteIcon from './favorite-icon'

export default

	components: { FavoriteIcon, RemoveModal }

	props:
		component: String
		productHandle: String
		productTitle: String
		type: String
		circle:
			type: Boolean
			default: false

	computed:

		# Is this product already favorited
		productFavorited: -> @productHandle in @$store.state.favorites.products

		# Use product title if provided, else fall back to handle
		productName: -> if @productTitle then @productTitle else @productHandle

		# The aria label for our button
		ariaLabel: ->
			if @productFavorited
			then "Remove #{@productName} From Favorites"
			else "Add #{@productName} To Favorites"

		favoritedText: ->
			if @productFavorited then @$t('product.favorite_pdp_added')
			else @$t('product.favorite_pdp_add')

		classes: ->
			circle: @circle
			favorited: @productFavorited

	methods:

		toggleFavorite: ->

			# Remove confirmation
			if @productFavorited
				mountOnBody RemoveModal,
					parent: this
					propsData: handle: @productHandle

			# Add favorite
			else
				await @$store.dispatch 'favorites/toggle', @productHandle

				# Update GTM
				@$gtm.push
					event: @$GTM_EVENTS.FAVORITE
					component: @component
					productHandle: @productHandle
					hasFavorites: @$store.getters['favorites/hasFavorites']

