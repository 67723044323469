import { makeShopifyGid } from 'library/services/helpers'

export default

	computed:

		# Whether or not a product is a bundle
		productIsBundle: -> !!@product?.bundleVariants?.length

		hasRelatedBundles: -> !!@relatedBundles?.length

		relatedBundles: ->
			# Break early if we're looking at a bundle
			if @productIsBundle then return []

			# Look throught for related bundles
			relatedBundles = []
			@variants.forEach (variant) =>
				variantGid = makeShopifyGid variant.id, 'ProductVariant'
				bundles = @$store.state.bundles.bundles.filter (bundle) =>
					bundle.bundleVariants?.find (variant) =>
						variant.variantId == variantGid
				relatedBundles = relatedBundles.concat bundles
			# Filter out duplicate bundles by building a set and spreading into an array
			[...new Set(relatedBundles)]

		# Build out our pseudo variants that alias to bundles
		bundleVariants: ->
			return [] if @productIsBundle or !@hasRelatedBundles
			@relatedBundles.map (bundle) -> {
				...bundle
				isBundle: true
				featuredImage: bundle.variant.image
			}