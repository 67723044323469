

















import CountBadge from 'library/components/global/count-badge'
export default

	components: { CountBadge }

	computed:

		# Now using cart to check for hidden items
		cart: -> @$store.state.cart

		# VueX accessors
		count: -> @$store.getters['cart/itemCount']

		# Cart state aria label
		cartAriaLabel: -> "Open cart containing " + @label + " items"

