













Flickity = require 'flickity' if process.browser
import dimensions from 'library/styles/vars/dimensions'
import breakpoints from 'library/styles/vars/breakpoints.json'
import throttle from 'lodash/throttle'
import accessibilityMixin from './carousel-accessibility'

export default

	mixins: [accessibilityMixin],

	props:
		value: Boolean # For v-model support
		columns: Number # For automatic render of columns
		fillHeight: Boolean # Calculate the max card height and expand all
		config: # Flickity config
			type: Object
			default: -> {}

	data: ->
		hideImgs: false
		dragging: false
		enabled: null
		height: null

	# The carousel does not automatically detect when slides have changed so if
	# there were no slides in the carousel at mount, immediately eject.
	mounted: ->
		return unless @$slots.default?.length
		@$defer @setEnabled

	# Cleanup
	destroyed: -> @destroy()

	computed:

		# Max number of columns to show at desktop.  Default is 4.
		columnCountDesktop: -> @columns || 4

		# Max number of columns that could fit horizontally in the current viewport width
		# regardless of columnCountDesktop.
		columnCountResponsiveMax: ->
			return 4 if @winW > 1190
			return 3 if @winW >= parseInt breakpoints['tablet-landscape'] # 1024
			return 2 if @winW >= parseInt breakpoints.tablet # 768
			return 1

		# Number of columns currently showing
		columnCountResponsive: -> return Math.min @columnCountResponsiveMax, @columnCountDesktop

		# Set the container height when enabled
		styles: -> height: @height + 'px' if @enabled and @height?

		# Add classes
		classes: -> [
			"columns-#{@columnCountDesktop}"
			'hide-imgs': @hideImgs
			enabled: @enabled
			disabled: @enabled == false
			pending: @enabled == null
			'has-dots': @compositeConfig.pageDots and @enabled
			'fill-height': @fillHeight
		]

		# Number of cards/items in this carousel
		itemCount: -> @$slots.default?.length || 0

		# Number of pages of items we can paginate through
		pageCount: -> Math.ceil (@itemCount / @columnCountResponsive)

		# Make the composite config
		compositeConfig: -> {

			# Good alignment defaults
			contain: true
			percentPosition: true
			cellAlign: 'left' # All carousels currently start off left aligned

			# Show dots and arrows by default (also the Flickity defaults)
			pageDots: true
			prevNextButtons: true

			# We'll be calculating the height ourselves
			setGallerySize: !@fillHeight

			# Prop config
			...@config

			# Merge startup listeners with props
			on: {
				...(@config.on || {}),
				ready: =>
					@config.on?.ready?()
					@toggleImgs()
					@$wait 100, @setHeight # IE needed more than a 0ms delay
					@$wait 100, @makeOnlyVisibleSlidesFocusable # Make focusable visible elements
				change: (index) =>
					@config.on?.change?(index)
					@$wait 100, @makeOnlyVisibleSlidesFocusable # To make focusable visible elements after clicking next/prev buttons
					@$emit 'change', index
			}
		}

		# Get the window width
		winW: -> @$store.state.layout.viewport.width

		# Adjustments made to the height value
		heightAdjustment: -> switch
			when !!@columns then parseInt dimensions['heavy-tapered-shadow-blur']
			else 0

	watch:

		# Watch for viewport width changes
		winW: ->
			@setEnabled()
			@setHeight()

		# Init or destroy flickity and emit events when doing so.  I had to go
		# this route because watchCss did not trigger when classes were changed.
		# I'm guessing it is only checked on window resize.
		enabled: ->
			@$nextTick ->
				if @enabled then @init() else @destroy()
				@$emit 'input', @enabled

		# This was done, in particular, to fix initing the Flickity layout on
		# mobile.
		height: -> @flickity?.resize()

	methods:

		# Boot up Flickity, adding own event listeners
		init: ->
			return if @flickity # Prevent accidental double init
			@flickity = new Flickity @$el, @compositeConfig

			# Used to prevent clicks inside card from working until dragging done
			@flickity.on 'dragStart', => @dragging = true
			@flickity.on 'dragEnd', => @$defer => @dragging = false

			@addAccessibility() # Add accessibility from mixin

		# Prevent clicks on JS listeners from working while dragging
		onClick: (e) ->
			if @dragging
				e.preventDefault()
				e.stopPropagation()

		# Toggle the display none of internal visuals to trigger a new
		# intersection observer check, because the load detection was failing
		# when Flickity mounts. Flickity requires it to be added via config
		# object
		toggleImgs: ->
			@hideImgs = true
			@$defer => @hideImgs = false

		# Conditionally enable or disable Flickity if the children aren't wide
		# enough to fill the carousel
		setEnabled: ->

			# Abort if no cards
			return unless @itemCount

			# Abort if carousel has been removed from DOM
			return unless @$el.offsetParent

			# Get the width of all the contents, added up
			slotWidth = @$slots.default
			.filter (vm) -> vm.elm.nodeType == Node.ELEMENT_NODE
			.reduce (sum, vm) ->
				style = window.getComputedStyle vm.elm
				sum + vm.elm.offsetWidth +
					parseInt(style.marginLeft || 0) +
					parseInt(style.marginRight || 0)
			, 0

			# If no width was detected, try again in a bit
			unless slotWidth then @$wait 100, => @setEnabled()

			# Enable Flickity if the slotted content is wider than the element,
			# adding 2 to prevent rounding issues
			else @enabled = slotWidth > @$el.offsetWidth + 2

		# Loop through all cards, temporarily setting their height to auto so they
		# can be measured without the influence of height:100%, and find the
		# tallest card height.
		setHeight: ->
			return unless @fillHeight and @enabled and @$slots.default?.length
			@height = @$slots.default
			.filter (vm) -> vm.elm.nodeType == Node.ELEMENT_NODE
			.reduce((maxHeight, vm) =>
				vm.elm.style.height = 'auto'
				height = vm.elm.offsetHeight
				vm.elm.style.height = null
				return Math.max maxHeight, height
			, 0) + @heightAdjustment

		# Destroy the Flickity instance
		destroy: ->
			return unless @flickity

			# Remove liveregion div created from addAccessibility()
			@flickity.element.removeChild(@flickity?.element?.querySelector('.liveregion'))

			@flickity.destroy()
			delete @flickity

		# Reload, like if the children change
		reload: ->
			@destroy()
			@$nextTick @init

		# go to index
		goToIndex: (index) ->
			@flickity.select(index, false, true)

