





















import QuickShop from 'library/components/global/modals/quick-shop/quick-shop'
import ProductCardBtnContainer from 'library/components/global/product-card/product-card-btn-container'
import { mountOnBody } from 'library/services/helpers'
import { getShopifyId } from 'library/services/helpers'

export default

	props:
		productId: String | Number
		available: Boolean

	data: -> adding: false

	components: {
		QuickShop
		ProductCardBtnContainer
	}

	computed:

		ctaText: ->
			if @available
			then @$t('cart.quick_shop')
			else @$t('cart.unavailable')

	methods:

		# Fire 'View Quick Shop' GTM event
		fireViewQuickShopEvent: ->
			@$gtm.push
				event: @$GTM_EVENTS.VIEW_QUICK_SHOP
				component: @$GTM_COMPONENTS.PRODUCT_CARD

		# open the modal
		openQuickModal: ->
			@fireViewQuickShopEvent()

			mountOnBody QuickShop,
				parent: this
				propsData: id: @productId

