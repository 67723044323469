




























































import { isShopifyUrl, makeNuxtUrl } from 'library/services/helpers'
import LocaleSelector from 'library/components/global/locale-selector'
import NewsletterSignup from 'library/components/layout/footer/newsletter-signup'
import footerMountains from '~/assets/images/mountains.svg'
export default

	components: {
		LocaleSelector
		NewsletterSignup
	}

	data: -> { footerMountains }

	computed:
		classes: -> [
			'international' if @international
		]

		international: -> !@$cartEnabled

		# Get the footer nav columns
		columns: ->
			@$store.state.globals.footerNavigation?.children?[0]?.footerMenuColumns

		socialLinks: -> [
			{
				platform: 'facebook'
				platformUrl: 'https://www.facebook.com/clifbar'
			},
			{
				platform: 'instagram'
				platformUrl: 'https://www.instagram.com/clifbar/'
			},
			{
				platform: 'twitter'
				platformUrl: 'https://twitter.com/ClifBar'
			},
			{
				platform: 'youtube'
				platformUrl: 'https://www.youtube.com/clifbarcompany'
			}
		]

