import {
	objectExists,
	fetchVariants,
	makeBundlePrice,
	makeBundleCompareAtPrice,
	makeBundleVariantTitle,
	twoDecimals
} from './bundlingHelpers'

// Execute and promise bundleRollup() for each shopify product of an array
export const bundleRollupAll = async (shopifyProducts, { $storefront }) => {
	return await Promise.all(shopifyProducts.map((shopifyProduct) => {
		return bundleRollup(shopifyProduct, { $storefront })
	}))
}

// Take a Shopify product that represents a bundle and calculate values lile
// the price by looping up it's child variants and performing operations. This
// uses memoize to prevent extra work being done on subequent lookups.
export const bundleRollup = async (shopifyProduct, { $storefront }) => {

	// Require shopifyProduct to exist as object; escape if not
	if (!objectExists(shopifyProduct) || !shopifyProduct.id) {
		console.error('No shopifyProduct object')
		return shopifyProduct;
	};

	// Check to see if our product is flagged as a bundle
	let isTaggedAsBundle = (!!shopifyProduct.tags &&
			!!shopifyProduct.tags.length &&
			shopifyProduct.tags.includes('Bundle'))

	// If it's not tagged as a bundle, stop running throught the rollup
	if (!isTaggedAsBundle) {
		return shopifyProduct
	}

	// Require $storefront to exist as object; escape if not
	if (!$storefront) {
		console.error('No Storefront object')
		return shopifyProduct;
	};

	let bundlingDataExists = !!(objectExists(shopifyProduct.bundling) &&
		shopifyProduct.bundling.bundleVariants &&
		// Could be a string or an array at this point
		shopifyProduct.bundling.bundleVariants.length)

	// If product is tagged as a bundle and data doesn't exists,
	// then set availability to false
	if (isTaggedAsBundle && !bundlingDataExists) {
		// Set product availability to false
		shopifyProduct.available = false
		// If product gets to the quickshop modal then make sure it's not addable
		shopifyProduct.variants[0].available = false

		return shopifyProduct;
	};

	// If the product came from Algolia, the bundleVariants may be in a json string
	if (typeof shopifyProduct.bundling.bundleVariants == 'string') {
		shopifyProduct.bundling.bundleVariants = JSON.parse(shopifyProduct.bundling.bundleVariants);
	}

	// If the bundle variants are not an array or are empty, escape
	if (!Array.isArray(shopifyProduct.bundling.bundleVariants) ||
		shopifyProduct.bundling.bundleVariants.length == 0)
	{
		return shopifyProduct
	}

	// Alias our bundled variant ids
	let parentBundledVariants = shopifyProduct.bundling.bundleVariants

	// Filter out variantIds that are null
	// This happens when a bundle is setup in Craft with a Product Variant
	// reference that isn't valid on a given store (a bundle on prod viewed on UAT)
	let unlistedVariants = parentBundledVariants.filter(({variantId}) => {
		return variantId == null
	})
	// The Variants that have a variantId
	let listedVariants = parentBundledVariants.filter(({variantId}) => {
		return variantId !== null
	})

	// Array of variant ids
	let bundledVariantsIds = listedVariants.map(({variantId}) => variantId)

	// Fetch our bundled variants' data
	// and maintain count from our parent bundle product
	let bundleVariants = (await fetchVariants(bundledVariantsIds, {$storefront}))
		.filter((variant) => !!variant)
		.map((variant) => {
			return {
				...variant,
				// Get the count from the variant with the same id in our parent array
				count: parentBundledVariants.find(({variantId}) => {
						return variantId == variant.id
					}).count
			}
	})

	// If any of the bundle variants were filtered out, like if they were in
	// draft mode or otherwise inaccessible to the Storefront API, then move
	// them to unlistedVariants
	listedVariants = listedVariants.filter((listedVariant) => {
		const variantWasFetchable = bundleVariants.some(({ id }) =>
			id == listedVariant.variantId)
		if (variantWasFetchable) return true
		unlistedVariants.push(listedVariant)
		return false
	})

	// Sum up the total prices of the products
	let totalPrice = {amount: twoDecimals(makeBundlePrice(bundleVariants))}

	// Sum up the compareAtPrice, using standard price when compareAtPrice
	// is unavailable
	let compareAtPrice = {amount: twoDecimals(makeBundleCompareAtPrice(bundleVariants))}

	// Set our compare at price to null if it is the same as the total
	// (mimicking the Shopify standard)
	if (compareAtPrice.amount === totalPrice.amount) {
		compareAtPrice = null;
	}

	// Remerge our unlisted variants into the bundleVariants to be stored
	// onto the product data
	bundleVariants = [
		...bundleVariants,
		...unlistedVariants
	]

	// Initiate our available variable
	let available
	// If we have unlisted products, then bundle will not be available
	if (unlistedVariants.length > 0) {
		available = false
	} else {
		// Compile product availability from variants
		available = bundleVariants.every(({availableForSale}) => availableForSale)
	}

	// Shopify adds a variant even if the product has none, this becomes
	// pertinent on pages like Favoriting; where data is hinged on the
	// primary variant
	// Assemble our final result, slotting in the compiled properties
	shopifyProduct.variants = [{
		...shopifyProduct.variants[0],
		available,
		price: totalPrice,
		compareAtPrice: compareAtPrice,
		title: makeBundleVariantTitle(bundleVariants),
		name: makeBundleVariantTitle(bundleVariants)
	}]

	// Populate data in the product's variant
	return {
		// Add a flag so we know if the rollup has been run on a product
		_productRollupComplete: true,
		...shopifyProduct,
		bundleVariants,
		price: totalPrice,
		compareAtPrice,
		available
	}

// Base the memoize cache on the shopify product id
}
