# Map Craft image objects into params for visual
import Visual from 'vue-visual'
import { makeImgixUrl } from 'library/services/helpers'
export defaultPlaceholderColor = '#f3f3f2'
export default
	name: 'CraftVisual'
	functional: true

	# Get the index of the block this may be in
	inject:
		blockIndex: default: undefined
		hasExperiment: default: false

	props:

		# A Craft image object with width, height, etc
		image: Object|Array
		video: Object|Array

		# Size
		aspect: Number|Boolean
		natural: Boolean
		noUpscale: Boolean
		width: Number
		height: Number

		# Clear placeholder color, like for logos
		noPlaceholder: Boolean

		# CSS image sizes rules
		sizes: String

		# Set base booleans to an undefined default so we can test whether they
		# were explicitly made false or are actually undefined
		preload:
			type: Boolean
			default: undefined
		lazyload:
			type: Boolean
			default: undefined

		# Passthrough props
		expand: Boolean
		objectFit: String
		objectPosition: String
		transition: String

	render: (create, { props, injections, data, children, scopedSlots }) ->

		# Get the assets, either of which is optional
		image = getAssetObject props.image
		video = getAssetObject props.video

		if props.natural then imageUrl = makeImgixUrl image
		else imageUrl = makeImgixUrl image, resizeWidths[0]

		# Decide if there is a placeholder color
		placeholderColor = if props.noPlaceholder
		then null else defaultPlaceholderColor

		# If no asset but an aspect, still create the Visual instance which
		# becomes a placeholder space
		unless image or video
			aspect = props.aspect or image?.width / image?.height
			return unless aspect or children
			return create Visual, { ...data, props: {
				aspect, placeholderColor
			} }, children

		# Figure out the aspect ratio
		aspect = switch
			when props.aspect == false then undefined
			when props.aspect == undefined then image?.width / image?.height
			else props.aspect

		# Passthrough the width and height
		{ width, height } = if props.natural and image then image else props

		# Apply a max-width if no upscale is set
		maxWidth = if props.noUpscale then image?.width

		# Helpers for automatically creating sizes
		sizes = if props.sizes then sizesHelpers props.sizes
		else if width then "#{parseInt(width)}px"

		# Warn developers to specify a sizes prop
		if imageUrl and !sizes and process.env.APP_ENV == 'dev'
		then console.debug "No sizes prop for #{imageUrl}"

		# Were sources created upstream, by, for instance, responsive-viusal
		hasSources = !!scopedSlots['image-source']

		# Disable lazy loading when image is critical (in the first block) or on
		# a route that is running experiments. Also, if critical, enable preload
		# link tag.
		isCriticalImage = injections.blockIndex < 2
		preload = props.preload ? isCriticalImage
		lazyload = props.lazyload ? not isCriticalImage &&
			not preload && not injections.hasExperiment

		# Instantiate a Visual instance
		create Visual, {
			...data
			props: {

				# Image
				image: imageUrl
				sizes
				...(if hasSources || props.natural then {} else {
					srcset: makeSrcset image, max: width || maxWidth
				})

				# Video
				video: video?.url
				autoplay: true
				loop: true
				muted: true

				# Layout
				aspect
				width
				height
				maxWidth
				objectFit: props.objectFit
				objectPosition: props.objectPosition || makeObjectPosition image
				expand: props.expand

				# Loading
				preload
				lazyload
				transition: unless lazyload then '' else props.transition
				placeholderColor

				# Misc
				alt: image?.title || video?.title
			}

			# Add transition as a reveal class if a critical image
			class: [
				data.class
				" #{props.transition}-reveal" if not lazyload and props.transition
			]

		# Slot children
		}, children

# Craft returns assets in an array, so get the first asset in the list
export getAssetObject = (asset) ->
	if Array.isArray asset
		if asset.length
		then return asset[0]
		else return null
	return asset

# Make a CSS background position value
makeObjectPosition = (image) ->
	return '50% 50%' unless image?.focalPoint
	"#{image.focalPoint[0] * 100}% #{image.focalPoint[1] * 100}%"

# The srcset values need to match those used in transforms in the query
resizeWidths = [1800,1440,1024,768,425,210]
export makeSrcset = (image, { max } = {}) ->
	return unless image

	# Passthru gifs
	if image.mimeType == 'image/gif' then return makeImgixUrl image

	# Don't make srcs if the there is max width restriction
	sizes = unless max then resizeWidths
	else
		maxWidth = 2 * parseInt max
		resizeWidths.filter (size) -> size <= maxWidth

	# Make the srcset string
	sizes.map (size) ->
		"#{makeImgixUrl(image, size)} #{size}w"
	.join(',')

# Make sizes shorthands
export sizesHelpers = (sizes) -> switch sizes
	when '4 column' then '''
			(min-width: 1440px) 360px,
			(max-width: 767px) 100vw,
			(max-width: 1023px) 50vw,
			(max-width: 1190px) 33vw,
			25vw
		'''
	when '3 column' then '''
			(min-width: 1440px) 480px,
			(max-width: 767px) 100vw,
			(max-width: 1023px) 50vw,
			33vw
		'''
	when '2/3 column' then '''
			(min-width: 1440px) 960px,
			(max-width: 1023px) 100vw,
			66vw
		'''
	when 'full-max-w' then '''
		(min-width: 1440px) 1440px,
		100vw
	'''
	when 'full' then '''
		(min-width: 1800px) 1800px,
		100vw
	'''
	else sizes
