














































import AddToCartBtn from './add-to-cart-btn'
import SubscriptionOptions from './subscription-options'
import 'vue-height-tween-transition/index.css'
import HeightTween from 'vue-height-tween-transition'
import { GTM_EVENTS, GTM_COMPONENTS } from 'library/constants'

export default

	components: { AddToCartBtn, SubscriptionOptions, HeightTween }

	inject:
		onQuickShopModal: default: false
		defaultToSubscribe: default: false

	props:
		product: Object
		variant: Object
		parentAddToCart: Function
		quantity: Number
		subscribeable:
			type: Boolean
			default: true # For backwards compatability

	data: ->
		choice: if @defaultToSubscribe then 'subscription' else 'cart'
		adding: false
		frequency: null
		sellingPlanAllocation: null

	computed:

		# Button text
		btnLabel: -> if @variant.available then 'Add to Cart' else 'Unavailable'

		component: ->
			if @onQuickShopModal
			then GTM_COMPONENTS.QUICK_SHOP_MODAL
			else GTM_COMPONENTS.PRODUCT_CARD

		# Make the add to card payload
		payload: -> {
			variantId: @$getShopifyId @variant.id
			quantity: @quantity || 1
			@component
		}

		isBundle: -> !!@product?.bundleVariants?.length

		isBundledVariant: -> !!@variant?.isBundle

		bundlePayload: ->
			# Source of the bundling meta data can be one of two options:
			# - currentVariant is the selected variant is a bundle that includes the
			#		product of the current quickshop modal
			# - product is when the current quickshop modal is a bundle itself
			source = if @variant?.isBundle then @variant else @product
			return {
				bundleSku: @variant.sku
				bundleVariants: source.bundleVariants.map (variant) =>
					variantId: if variant.id then variant.id else variant.variantId
					count: variant.count
				quantity: @quantity || 1
				@component
			}

		# Add subscription fields to the basic item payload
		subscriptionPayload: -> {
			...@payload
			sellingPlanId: parseInt @$getShopifyId @sellingPlanAllocation.sellingPlan.id
		}

		sellingPlanAllocations: -> @variant?.sellingPlanAllocations

		sellingPlans: -> @sellingPlanAllocations.map((plan) -> plan.sellingPlan)

		sellingPlanOptions: ->
			@sellingPlans.map (sellingPlan) -> sellingPlan?.options[0].value

	mounted: ->
		@sellingPlanAllocation = @sellingPlanAllocations?[0]
		@updateFrequency() unless !@sellingPlanAllocation

	watch:

		sellingPlanAllocation: -> @updateFrequency()

		# If the variant isn't subscribeable, reset the checkout choice
		subscribeable: -> @choice = 'cart' unless @subscribeable

	methods:

		# Add the variant to the cart
		addToCart: ->
			@adding = true

			switch
				when @choice == 'subscription'
					await @$store.dispatch 'cart/addSubscription', @subscriptionPayload
				when @isBundle or @isBundledVariant
					await @$store.dispatch 'cart/addBundle', @bundlePayload
				else await @$store.dispatch 'cart/addItem', @payload

			@$emit 'lineAdded', {
				@variant
				@quantity
				sellingPlanAllocation: @sellingPlanAllocation if @choice == 'subscription'
			}

			@$openCart() unless @onQuickShopModal

			@adding = false

		updateFrequency: ->
			@frequency = @sellingPlanAllocation?.sellingPlan?.options[0].value

