


























import AddToCartBtn from './add-to-cart-btn'
import Price from 'library/components/global/price'
import SubscriptionOptions from './subscription-options'
import { applySubscriptionDiscount } from 'library/services/ordergroove/helpers'
import { SUBSCRIPTION_DISCOUNT_PERCENTAGE } from 'library/services/ordergroove/constants'
import { GTM_EVENTS, GTM_COMPONENTS } from 'library/constants'

export default

	inject:
		onQuickShopModal: default: false
		defaultToSubscribe: default: false

	components: { AddToCartBtn, Price, SubscriptionOptions }

	props:
		variant: Object
		subscribed: Boolean # Is the user already subscribed
		subscribeable:
			type: Boolean
			default: true # For backwards compatability
		quantity: # Quantity to add (typically 1 for card, responsive if modal)
			type: Number
			default: 1

	data: ->
		choice: if @defaultToSubscribe then 'subscription' else 'cart'
		adding: false

	computed:

		# The label on the button
		cta: -> switch
			when !@variant.available then @$t('product.unavailable')
			when @choice == 'subscription' then switch
				when @subscribed then @$t('product.manage_subscription')
				else @$t('product.add_to_subscription')
			when @choice == 'cart' then @$t('product.add_to_cart')

		# Apply subscription pricing as a compare at price
		pricing: ->
			if @choice == 'cart' then @variant
			else
				price: applySubscriptionDiscount @variant.price
				compareAtPrice: @variant.price

		subscribeText: -> switch
			when @subscribed
				'Existing <br>Subscription'
			when @onQuickShopModal
				"Subscribe & <br>Save #{SUBSCRIPTION_DISCOUNT_PERCENTAGE}%"
			else "Add To My <br>Next Delivery"

	watch:

		# If the variant isn't subscribeable, reset the checkout choice
		subscribeable: -> @choice = 'cart' unless @subscribeable

		# Relay the current price
		pricing:
			immediate: true
			handler: -> @$emit 'pricing', @pricing

	methods:

		# Delegate what to do on click based on state
		onClick: ->
			switch @choice
				when 'subscription' then switch
					when @subscribed then @openSubscriptionManagement()
					else @openAddToSubscriptionModal()
				when 'cart' then @addToCart()

		# Redirect the user to manage their subscription
		openSubscriptionManagement: ->
			window.location = @$makeShopifyUrl '/account?view=subscriptions'

		# Simulate a click on the og-offer button to open it's modal
		openAddToSubscriptionModal: ->
			@$el.closest('.og-offer-parent').querySelector('og-upsell-button').click()

			# Waiting 1s here to ensure the modal contents are loaded and populated
			@$wait 1000, @setListenersOnModal()

			# Fire 'Add to Subscription' GTM event
			@$gtm.push
				event: GTM_EVENTS.ADD_TO_SUBSCRIPTION
				component: if @onQuickShopModal then GTM_COMPONENTS.QUICK_SHOP_MODAL else GTM_COMPONENTS.PRODUCT_CARD
				variantId: @$getShopifyId @variant.id

		# Set the listeners inside the OG modal
		setListenersOnModal: ->
			root = @$el.parentElement.parentElement.querySelector('og-when[test="subscriptionEligible"]')
			recurring = root.querySelector('.og__radio-line.recurring')
			oneTime = root.querySelector('.og__radio-line.one-time')
			intervalText = root.querySelector('.og__ship-interval')

			recurring.addEventListener 'click', ->
				if intervalText.classList.contains 'hidden'
					intervalText.classList.remove 'hidden'

			oneTime.addEventListener 'click', ->
				if intervalText.classList.contains 'hidden' then return
				intervalText.classList.add 'hidden'

		# Add the variant to the cart
		addToCart: ->
			@adding = true
			await @$store.dispatch 'cart/addItem', {
				variantId: @$getShopifyId @variant.id
				@quantity
				component: if @onQuickShopModal then 'Quick Shop Modal' else 'Product card'
			}
			@$emit 'lineAdded', { @variant, @quantity }
			@$openCart() unless @onQuickShopModal
			@adding = false
