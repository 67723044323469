export default

	methods:
		addAccessibility: ->
			@addTabIndexToDots()
			@addRoleToPrevNextButtons()
			@addLiveRegion()

		# Add tabindex and role to carousel nav dots (For accessibility)
		addTabIndexToDots: ->
			dots = @flickity?.element?.querySelector('.flickity-page-dots')
			if (!dots) then return
			Array.from(dots.children).forEach (dot,index) =>
				dot.tabIndex = 0
				dot.setAttribute 'role', 'button'
				dot.setAttribute 'aria-label', "Page dot #{index + 1}"
				dot.addEventListener 'keyup', (event) => if @isEnterOrSpacedPressed(event) then dot.click()

		# Adding Live Region to carousel. https://www.w3.org/WAI/tutorials/carousels/functionality/#announce-the-current-item
		addLiveRegion: ->
			liveregion = document.createElement('div')
			liveregion.setAttribute('aria-live', 'polite')
			liveregion.setAttribute('aria-atomic', 'true')
			liveregion.setAttribute('class', 'liveregion visually-hidden')
			@flickity.element.appendChild(liveregion)

		# Adding Role property to Prev and Next Buttons
		addRoleToPrevNextButtons: ->
			prevNextButtons = @flickity.element.querySelectorAll('.flickity-button')
			prevNextButtons.forEach (button) =>
				button.setAttribute('role', 'button')

		getFirstActiveSlideIndex: ->
			return ((@flickity?.cells.findIndex (cell) -> cell.element.classList.contains('active') || cell.element.classList.contains('is-selected')) || 0) + 1

		isEnterOrSpacedPressed: (event) ->
			return (event.keyCode == 13 || event.keyCode == 32)

		# Make visible slides accessible by keyboard Tab and make hidden ones not accessible
		makeOnlyVisibleSlidesFocusable: ->
			if !@flickity then return
			visibleCells = @flickity?.selectedCells
			hiddenCells = @flickity?.cells.filter (cell) -> !visibleCells.some (sCell) -> sCell.element.isEqualNode cell.element

			# Changing live region text. https://www.w3.org/WAI/tutorials/carousels/functionality/#announce-the-current-item
			@flickity.element.querySelector('.liveregion').textContent = 'Item ' + (@getFirstActiveSlideIndex() || 0) + ' of ' + @flickity?.cells.length;

			# If this is a product card slide
			if (visibleCells[0]?.element.classList.contains('product-card'))
				hiddenCells.forEach ({element}) ->
					element.querySelectorAll('.contents, .hover, button').forEach (el) ->
						el.setAttribute('tabindex', '-1')
				visibleCells.forEach ({element}) ->
					element.querySelectorAll('.contents, .hover, button').forEach (el) ->
						el.setAttribute('tabindex', '0')

			# If this is a regular slide
			else
				hiddenCells.forEach ({element}) -> element.setAttribute('tabindex', '-1')
				visibleCells.forEach ({element}) -> element.setAttribute('tabindex', '0')
