













































import CheckoutButton from 'library/components/global/buttons/checkout-button'
import DiscountCode from './discount-code'
import FlyoutSidebar from 'library/components/layout/flyout-sidebar'
import LineItem from './line-item'
import cartData from 'library/mixins/cart-data'
import { GTM_EVENTS, GTM_COMPONENTS } from 'library/constants'

export default

	mixins: [ cartData ]

	components: {
		CheckoutButton
		DiscountCode
		FlyoutSidebar
		LineItem
	}

	# Load data
	mounted: ->
		@$store.dispatch 'cart/fetchUnlessHydrated'
		@$gtm.push
			event: @$GTM_EVENTS.OPEN_SIDEBAR
			component: @$GTM_COMPONENTS.FLYOUT_CART

	# Close the flyout if all products are removed
	watch: hasItems: -> @$refs.flyout.close() unless @hasItems

