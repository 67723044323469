

















import Modal from './modal'
export default

	components: {
		Modal
	}

