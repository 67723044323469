













export default

	props:
		icon:
			type: String
			default: 'arrow'
		component: String

	computed:
		checkoutUrl: -> @$store.getters['cart/checkoutUrl']
		iconClasses: -> "icon-#{@icon}"

	methods:

		# Fire 'Checkout clicked' GTM event
		onCheckout: ->
			@$gtm.push {
				event: @$GTM_EVENTS.CHECKOUT_CLICKED
				@component
			}


