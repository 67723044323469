








































export default
	name: 'menu-tout'

	props:
		column: Object

