






























import SubscriptionRadio from './subscription-radio'
import { SUBSCRIPTION_DISCOUNT_PERCENTAGE } from 'library/services/ordergroove/constants'
export default

	components: { SubscriptionRadio }

	props:

		# The current seleted value of the radios, for use with v-model
		value: String

		# Force rendering as boxes for all viewport sizes
		forceBoxUi: Boolean

		# The text to show on the 2nd radio
		subscribeText:
			type: String
			default: -> "Subscribe & <br>Save #{SUBSCRIPTION_DISCOUNT_PERCENTAGE}%"

	computed:

		classes: -> if @forceBoxUi then 'forced-box' else 'standard-box'
