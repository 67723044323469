import memoize from 'lodash/memoize'

# Create a script tag and watch for it to load
export loadScript = memoize (src, {
	defer = false
	async = false
} = {}) -> return new Promise (resolve, reject) ->
	script = document.createElement('script')
	script.onload = resolve
	script.onerror = reject
	script.src = src
	script.defer = true if defer
	script.async = true if async
	document.head.appendChild script
