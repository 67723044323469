











































import { VForm, VTextField } from 'vuetify/lib'

export default

	components: {
		VForm
		VTextField
	}

	props:
		buttonTheme:
			type: String
			default: 'primary'

		inputTheme:
			type: String
			default: 'white'
			validator: (val) -> val in ['white', 'outline']

		size:
			type: String
			default: 'large'
			validator: (val) -> val in ['large', 'medium']

		eventId:
			type: String
			default: ''

		source:
			type: String
			default: ''

		buttonLabel:
			type: String
			default: 'text'
			validator: (val) -> val in ['text', 'arrow']

	data: ->
		valid: true
		submitting: false
		mustAgree: false

		recaptchaToken: null

		form:
			email: ''
			source: @source
			agree: false

		rules:
			email: [ @$validators.required, @$validators.email ]

		id: @getUid()

	computed:

		# Form submission payload
		payload: -> {
			...@form
			source: @form.source
			eventId: @eventId
		}

		# Make the agree to terms message
		agreeToTermsMsg: -> """
			#{@agreeToTermsIntro}
			<a href='/privacy-statement'>#{@$t('newsletter.privacy_statement')}</a>
			and <a href='/use-terms'>#{@$t('newsletter.terms_of_use')}</a>.
		"""

		# Switch the agree to terms opening message out depending on whether there
		# is a validation fail or not
		agreeToTermsIntro: ->
			unless @mustAgree
			then @$t('newsletter.i_have_read_and_agree_to_the')
			else @$t('newsletter.asterisk_you_must_agree_to_the')

		classes: -> [
			"input-theme-#{@inputTheme}"
			"size-#{@size}"
		]

	mounted: ->
		# If we know their email because they are logged in, use that
		@form.email = @$store.state.customer.email || ''

	methods:

		submit: ->
			# Require valid form
			return unless @$refs.form.validate()

			# Require agreeing to terms
			unless @form.agree then return @mustAgree = true

			# Prevent double submit
			return if @submitting
			@submitting = true

			# Submit the form
			try

				# Get the recaptcha token
				recaptchaToken = await @$getRecaptchaToken()

				payload = { ...@payload, recaptchaToken }

				hostname = window?.location?.hostname

				# Use Netlify function localhost if specified
				if process.env.NETLIFY_FUNCTIONS_URL
					netlifyFunctionOrigin = process.env.NETLIFY_FUNCTIONS_URL

				# On the prod shop subdomain use the absolute prod URL
				else if hostname == 'shop.clifbar.com'
					netlifyFunctionOrigin = 'https://www.clifbar.com/'

				# On the UAT shop subdomain use the absolute UAT URL
				else if hostname == 'uat-shop.clifbar.com'
					netlifyFunctionOrigin = 'https://uat-www.clifbar.com/'

				# On the prod www subdomain (and everywhere else) use the slash URL
				else
					netlifyFunctionOrigin = '/'

				await @$axios.post "#{netlifyFunctionOrigin}.netlify/functions/newsletter-signup", payload
				@$emit 'update:state', 'success'

			catch e
				@$emit 'update:state', 'error'
				return console.error e

			finally
				@submitting = false

			# Show thank you message
			@step++

			# Persist customer email
			@$store.commit 'customer/setCustomer', email: @form.email
			dataLayer.push event: @$GTM_EVENTS.NEWSLETTER_SUBMIT

		reset: ->
			# Reset fields
			@form.email = ''
			@form.agree = false
			# Emit initial state to any listening parents
			@$emit 'update:state', 'init'

		getUid: (strLength=8) -> Math.random().toString(36).substr(2, strLength)


