###
Designed for page/layout for checking if an experiment is active on the route
and loading experiment library when on prod
###
import { loadScript } from 'library/services/helpers'

# Settings
sdks =
	googleOptimize: 'https://www.googleoptimize.com/optimize.js?id=GTM-546ZWJ9'

export default

	# Used by VueVisual to disable lazy-loading
	provide: -> { @hasExperiment }

	# Load SDK during client side navigation.
	middleware: ({ route, store }) ->

		# Only client side, the experimentSdkScript computed prop should have
		# loaded it during SSR if initial request was for an experiented path.
		return unless process.client

		# Only on matching paths
		return unless sdkKey = getSdkKey store, route.path

		# Don't load if already loaded via SSR
		return if document.querySelector "[data-hid='#{sdkKey}']"

		# Do the load, returning a promise
		loadScript sdks[sdkKey]

	computed:

		# Is the current route running an experiment
		hasExperiment: -> hasExperiment @$store, @$route.path

		# Make the experiment script path for head function. This is only used
		# when SSRing. Otherwise, it's added via asyncData
		experimentsSdkScript: ->
			return unless process.server
			return unless sdkKey = getSdkKey @$store, @$route.path
			hid: sdkKey
			src: sdks[sdkKey]

###
Helpers that can be shared before and after components get hydrated
###

# Check for any experiments on path
hasExperiment = (store, path) -> !!getSdkKey store, path

# Get active sdk slug given the path
getSdkKey = (store, path) -> switch
	when hasGoogleOptimizeExperiment store, path then 'googleOptimize'

# Check if path has a google optimize experiment
hasGoogleOptimizeExperiment = (store, path) ->
	path in store.getters['globals/googleOptimizePaths']
