
























import CountBadge from 'library/components/global/count-badge'
export default

	components: { CountBadge }

	props:
		title: String
		to: String
		icon: String
		count: Number
		active: Boolean
		small: Boolean
		component: String # Used in GTM event

